var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.operationItem.result)?_c('div',[(_vm.rollingStock.closedDate)?_c('div',[_c('DxDataGrid',{attrs:{"id":"closeDocsFiles","key-expr":"fileId","data-source":_vm.operationItem.result.closedFiles,"columns":_vm.columnsFiles,"show-borders":true,"row-alternation-enabled":true,"show-row-lines":true,"customize-columns":_vm.customizeColumnsFiles},scopedSlots:_vm._u([{key:"is-verified-template",fn:function(ref){
var data = ref.data;
return [(data.data.isVerified)?_c('i',{staticClass:"fas fa-award font-22",staticStyle:{"color":"#00afef"}}):_vm._e()]}},{key:"file-name-template",fn:function(ref){
var data = ref.data;
return [_c('button',{staticClass:"btn btn-link btn-sm p-0 text-left",attrs:{"id":'btn-download-file-'+data.data.fileId,"type":"button"},on:{"click":function($event){return _vm.downloadFile(data.data.fileId, data.data.fileName)}}},[_vm._v(_vm._s(data.data.fileName))])]}}],null,false,2223732791)})],1):_vm._e(),(!_vm.rollingStock.closedDate)?_c('div',[(_vm.okMessage)?_c('div',{staticClass:"alert alert-success mb-3",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.okMessage)+" ")]):_vm._e(),(_vm.errorMessage)?_c('div',{staticClass:"alert alert-danger mb-3",attrs:{"role":"alert"}},[_c('strong',[_vm._v("Внимание!")]),_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('DxDataGrid',{attrs:{"id":"closeDocsFilesEdit","key-expr":"fileId","data-source":_vm.operationItem.result.closedFiles,"columns":_vm.columnsFiles,"show-borders":true,"row-alternation-enabled":true,"show-row-lines":true,"customize-columns":_vm.customizeColumnsFilesEdit,"editor-prepared":_vm.onEditorPrepared},on:{"toolbar-preparing":_vm.onToolbarPreparing,"editing-start":_vm.onEditingStart,"init-new-row":_vm.onInitNewRow,"saving":_vm.onSaving},scopedSlots:_vm._u([{key:"file-doc-editor",fn:function(){return [_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[(_vm.fileDocLabel)?_c('span',[_vm._v(_vm._s(_vm.fileDocLabel))]):_vm._e(),(!_vm.fileDocLabel)?_c('span',{staticClass:"text-danger"},[_vm._v("Файл не выбран")]):_vm._e()]),_c('div',{staticClass:"col-12 col-md-6 text-right"},[_c('DxButtonST',{attrs:{"id":"fileDoc-add-external","text":"Выбрать файл","type":"default","styling-mode":"contained"}})],1)]),_c('DxFileUploader',{attrs:{"id":"fileDoc","dialog-trigger":"#fileDoc-add-external","multiple":false,"upload-mode":"useForm","visible":false,"accept":"image/jpeg,image/jpg,image/png,application/pdf"},on:{"value-changed":_vm.fileDocValueChanged}})],1)]},proxy:true},{key:"is-verified-template",fn:function(ref){
var data = ref.data;
return [(data.data.isVerified)?_c('i',{staticClass:"fas fa-award font-22",staticStyle:{"color":"#00afef"}}):_vm._e()]}},{key:"file-name-template",fn:function(ref){
var data = ref.data;
return [_c('button',{staticClass:"btn btn-link btn-sm p-0 text-left",attrs:{"id":'btn-download-file-'+data.data.fileId,"type":"button"},on:{"click":function($event){return _vm.downloadFile(data.data.fileId, data.data.fileName)}}},[_vm._v(_vm._s(data.data.fileName))])]}}],null,false,3106675943)},[_c('DxEditing',{attrs:{"allow-updating":true,"allow-deleting":true,"allow-adding":true,"mode":"form"}},[_c('DxForm',{attrs:{"col-count":7,"show-colon-after-label":true,"show-validation-summary":true}},[_c('DxColCountByScreen',{attrs:{"xs":1,"sm":1}})],1)],1),_c('DxColumn',{attrs:{"width":40,"allow-sorting":false,"data-field":"isVerified","caption":"","allow-editing":false,"cell-template":"is-verified-template"}},[_c('DxFormItem',{attrs:{"visible":false}})],1),_c('DxColumn',{attrs:{"width":100,"allow-sorting":false,"data-field":"closedDate","caption":"Дата ЗП","data-type":"date","format":"dd.MM.yyyy"}},[_c('DxFormItem',{attrs:{"col-span":1}},[_c('DxLabel',{attrs:{"text":"Дата ЗП","location":"top"}})],1),_c('DxRequiredRule',{attrs:{"message":"Укажите Дату ЗП"}})],1),_c('DxColumn',{attrs:{"width":120,"allow-sorting":false,"data-field":"fileKind","caption":"Вид документа"}},[_c('DxFormItem',{attrs:{"col-span":2,"editor-options":{
                                    dataSource: _vm.fileKinds,
                                    acceptCustomValue: true,
                                    onCustomItemCreating: _vm.onCustomItemCreating
                                },"editor-type":"dxSelectBox"}},[_c('DxLabel',{attrs:{"text":"Вид документа","location":"top"}})],1),_c('DxRequiredRule',{attrs:{"message":"Укажите Вид документа"}})],1),_c('DxColumn',{attrs:{"allow-sorting":false,"data-field":"fileDoc","caption":"Файл","visible":false,"edit-cell-template":"file-doc-editor"}},[_c('DxFormItem',{attrs:{"col-span":4,"editor-options":{value: _vm.fileDocLabel}}},[_c('DxLabel',{attrs:{"text":"Файл","location":"top"}}),_c('DxRequiredRule',{attrs:{"message":"Выберите файл"}})],1)],1),_c('DxColumn',{attrs:{"allow-sorting":false,"data-field":"fileName","caption":"Имя файла","allow-editing":false,"cell-template":"file-name-template"}},[_c('DxFormItem',{attrs:{"visible":false}})],1),_c('DxColumn',{attrs:{"allow-sorting":false,"data-field":"note","caption":"Примечание"}},[_c('DxFormItem',{attrs:{"col-span":7}},[_c('DxLabel',{attrs:{"text":"Примечание","location":"top"}})],1)],1),_c('DxColumn',{attrs:{"type":"buttons","caption":"Действие"}},[_c('DxButton',{attrs:{"name":"edit","text":"Изменить","css-class":"btn btn-light btn-sm text-primary d-block d-md-inline-block"}}),_c('DxButton',{attrs:{"name":"delete","css-class":"btn btn-light btn-sm text-danger d-block d-md-inline-block","text":"Удалить","disabled":_vm.fileDocImageSource == ''}})],1)],1)],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }