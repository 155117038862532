<template>
    <div>
        <div v-if="operationItem.result">
            <div v-if="rollingStock.closedDate" class="row select-operation">
                <div class="col-12 mb-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="text-black-50">
                        Котрольное время прибытия на погрузку: {{ operationItem.result.checkTime ? operationItem.result.checkTime : '-' | moment("DD.MM.YYYY HH:mm") }}
                    </p>
                </div>
                <div class="col-12 col-md-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Время прибытия:
                    </p>
                    <p class="item-text">
                        {{ operationItem.result.arrivalTime ? operationItem.result.arrivalTime : '-' | moment("DD.MM.YYYY HH:mm") }}
                    </p>
                </div>
                <div class="col-12 col-md-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Начало погрузки:
                    </p>
                    <p class="item-text">
                        {{ operationItem.result.startOperationTime ? operationItem.result.startOperationTime : '-' | moment("DD.MM.YYYY HH:mm") }}
                    </p>
                </div>
                <div class="col-12 col-md-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Окончание погрузки:
                    </p>
                    <p class="item-text">
                        {{ operationItem.result.endOperationTime ? operationItem.result.endOperationTime : '-' | moment("DD.MM.YYYY HH:mm") }}
                    </p>
                </div>
                <div class="col-12 col-md-3 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Время убытия:
                    </p>
                    <p class="item-text">
                        {{ operationItem.result.departureTime ? operationItem.result.departureTime : '-' | moment("DD.MM.YYYY HH:mm") }}
                    </p>
                </div>
                <div class="col-12 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Комментарий:
                    </p>
                    <p class="item-text">
                        {{ operationItem.result.note ? operationItem.result.note : '-' }}
                    </p>
                </div>
            </div>
            <div v-if="!rollingStock.closedDate" class="row mb-3">
                <div class="col-12 d-flex flex-row flex-md-column justify-content-start">
                    <p class="text-black-50">
                        Котрольное время прибытия на погрузку: {{ operationItem.result.checkTime ? operationItem.result.checkTime : '-' | moment("DD.MM.YYYY HH:mm") }}
                    </p>
                </div>
                <div class="col-12">
                    <div id="form-container">
                        <div v-if="okMessage" class="alert alert-success mb-3" role="alert">
                            {{ okMessage }}
                        </div>
                        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                            <strong>Внимание!</strong> {{ errorMessage }}
                        </div>
                        <form class="edit-form" @submit.prevent="onSubmit">
                            <DxForm
                                    id="form"
                                    :col-count="1"
                                    :form-data="formData"
                                    :read-only="false"
                                    :show-colon-after-label="true"
                                    :show-validation-summary="true"
                                    :label-location="labelLocation"
                                    :disabled="isEdit"
                                    @field-data-changed="formFieldDataChanged"
                            >
                                <DxGroupItem
                                        :colCount="4"
                                >
                                    <DxColCountByScreen :xs="1" :sm="2"/>
                                    <DxSimpleItem
                                            data-field="arrivalTime"
                                            :editor-options="{type: 'datetime', displayFormat: 'dd.MM.yyyy HH:mm'}"
                                            editor-type="dxDateBox"
                                    >
                                        <DxLabel text="Время прибытия"/>
                                        <DxRequiredRule message="Укажите Время прибытия"/>
                                    </DxSimpleItem>
                                    <DxSimpleItem
                                            data-field="startOperationTime"
                                            :editor-options="{type: 'datetime', displayFormat: 'dd.MM.yyyy HH:mm'}"
                                            editor-type="dxDateBox"
                                    >
                                        <DxLabel text="Начало погрузки"/>
                                        <DxCustomRule message="Укажите Начало погрузки" type="custom" :reevaluate="true" :validation-callback="validateStartOperationTime1" />
                                        <DxCustomRule message="Время начала погрузки не может быть меньше времени прибытия" type="custom" :reevaluate="true" :validation-callback="validateStartOperationTime2" />
                                    </DxSimpleItem>
                                    <DxSimpleItem
                                            data-field="endOperationTime"
                                            :editor-options="{type: 'datetime', displayFormat: 'dd.MM.yyyy HH:mm'}"
                                            editor-type="dxDateBox"
                                    >
                                        <DxLabel text="Окончание погрузки"/>
                                        <DxCustomRule message="Укажите Окончание погрузки" type="custom" :reevaluate="true" :validation-callback="validateEndOperationTime1" />
                                        <DxCustomRule message="Время окончания погрузки не может быть меньше времени начала погрузки" type="custom" :reevaluate="true" :validation-callback="validateEndOperationTime2" />
                                    </DxSimpleItem>
                                    <DxSimpleItem
                                            data-field="departureTime"
                                            :editor-options="{type: 'datetime', displayFormat: 'dd.MM.yyyy HH:mm'}"
                                            editor-type="dxDateBox"
                                    >
                                        <DxLabel text="Время убытия"/>
                                        <DxCustomRule message="Время убытия не может быть меньше времени oкончание погрузки" type="custom" :reevaluate="true" :validation-callback="validateDepartureTime2" />
                                    </DxSimpleItem>
                                </DxGroupItem>
                                <DxSimpleItem
                                        data-field="note"
                                        :colSpan="1"
                                >
                                    <DxLabel text="Комментарий"/>
                                </DxSimpleItem>
                                <DxButtonItem
                                        :button-options="buttonOptionsSave"
                                        cssClass="pt-4"
                                        :colSpan="3"
                                />
                            </DxForm>
                        </form>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row select-operation">
                <div class="col-12 col-md-6 mb-3 d-flex flex-column justify-content-start">
                    <p class="mr-2">
                        Фото груза после погрузки:
                    </p>
                    <p class="item-text" v-if="operationItem.result.foto4">
                        <button :id="'btn-download-file-'+operationItem.result.foto4.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(operationItem.result.foto4.fileId, operationItem.result.foto4.fileName)">{{ operationItem.result.foto4.fileName }}</button>
                    </p>
                    <p class="item-text" v-if="!operationItem.result.foto4">
                        Нет файла
                    </p>
                    <div class="mt-3">
                        <div>
                            <div v-if="okMessageAddPhoto4" class="alert alert-success mb-3" role="alert">
                                {{ okMessageAddPhoto4 }}
                            </div>
                            <div v-if="errorMessageAddPhoto4" class="alert alert-danger mb-3" role="alert">
                                <strong>Внимание!</strong> {{ errorMessageAddPhoto4 }}
                            </div>
                            <form class="add-photo-4-form">
                                <DxForm
                                        id="form-add-photo-4"
                                        :col-count="1"
                                        :form-data="formDataAddPhoto4"
                                        :read-only="false"
                                        :show-colon-after-label="true"
                                        :show-validation-summary="true"
                                        :label-location="labelLocation"
                                >
                                    <DxSimpleItem>
                                        <DxLabel :visible="false" text="" />
                                        <template #default>
                                            <div>
                                                <div class="row text-left">
                                                    <div class="col-12 mb-3">
                                                        <img :src="photo4FileImageSource" class="img-thumbnail rounded" style="min-height: 200px; min-width: 200px; max-height: 200px" :alt="photo4FileLable ? 'Загрузка: '+photo4FileLable : ''">
                                                    </div>
                                                    <div class="col-12">
                                                        <DxButton
                                                                v-if="!isAddPhoto4"
                                                                id="photo4File-add-external"
                                                                text="Загрузить фото"
                                                                type="default"
                                                                styling-mode="contained"
                                                                :disabled="rollingStock.closedDate != undefined || operationItem.result.arrivalTime == undefined"
                                                        />
                                                        <DxButton
                                                                v-if="isAddPhoto4 && !rollingStock.closedDate"
                                                                text="Загрузка..."
                                                                type="default"
                                                                styling-mode="contained"
                                                                :disabled="true"
                                                        />
                                                    </div>
                                                </div>
                                                <DxFileUploader
                                                        id="photo4File"
                                                        dialog-trigger="#photo4File-add-external"
                                                        :multiple="false"
                                                        upload-mode="useForm"
                                                        :visible="false"
                                                        accept="image/jpeg,image/jpg,image/png"
                                                        @value-changed="photo4FileValueChanged"
                                                />
                                            </div>
                                        </template>
                                    </DxSimpleItem>
                                </DxForm>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-3 d-flex flex-column justify-content-start">
                    <p class="mr-2">
                        Фото пломбы после погрузки:
                    </p>
                    <p class="item-text" v-if="operationItem.result.foto2">
                        <button :id="'btn-download-file-'+operationItem.result.foto2.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(operationItem.result.foto2.fileId, operationItem.result.foto2.fileName)">{{ operationItem.result.foto2.fileName }}</button>
                    </p>
                    <p class="item-text" v-if="!operationItem.result.foto2">
                        Нет файла
                    </p>
                    <div class="mt-3">
                        <div>
                            <div v-if="okMessageAddPhoto2" class="alert alert-success mb-3" role="alert">
                                {{ okMessageAddPhoto2 }}
                            </div>
                            <div v-if="errorMessageAddPhoto2" class="alert alert-danger mb-3" role="alert">
                                <strong>Внимание!</strong> {{ errorMessageAddPhoto2 }}
                            </div>
                            <form class="add-photo-2-form">
                                <DxForm
                                        id="form-add-photo-2"
                                        :col-count="1"
                                        :form-data="formDataAddPhoto2"
                                        :read-only="false"
                                        :show-colon-after-label="true"
                                        :show-validation-summary="true"
                                        :label-location="labelLocation"
                                >
                                    <DxSimpleItem>
                                        <DxLabel :visible="false" text="" />
                                        <template #default>
                                            <div>
                                                <div class="row text-left">
                                                    <div class="col-12 mb-3">
                                                        <img :src="photo2FileImageSource" class="img-thumbnail rounded" style="min-height: 200px; min-width: 200px; max-height: 200px" :alt="photo2FileLable ? 'Загрузка: '+photo2FileLable : ''">
                                                    </div>
                                                    <div class="col-12">
                                                        <DxButton
                                                                v-if="!isAddPhoto2"
                                                                id="photo2File-add-external"
                                                                text="Загрузить фото"
                                                                type="default"
                                                                styling-mode="contained"
                                                                :disabled="rollingStock.closedDate != undefined || operationItem.result.arrivalTime == undefined"
                                                        />
                                                        <DxButton
                                                                v-if="isAddPhoto2"
                                                                text="Загрузка..."
                                                                type="default"
                                                                styling-mode="contained"
                                                                :disabled="true"
                                                        />
                                                    </div>
                                                </div>
                                                <DxFileUploader
                                                        id="photo2File"
                                                        dialog-trigger="#photo2File-add-external"
                                                        :multiple="false"
                                                        upload-mode="useForm"
                                                        :visible="false"
                                                        accept="image/jpeg,image/jpg,image/png"
                                                        @value-changed="photo2FileValueChanged"
                                                />
                                            </div>
                                        </template>
                                    </DxSimpleItem>
                                </DxForm>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import jQuery from 'jquery';
    let $ = jQuery;
    import {httpClient} from '../../../../shared/services';
    import {
        DxForm,
        DxGroupItem,
        DxColCountByScreen,
        DxLabel,
        DxRequiredRule,
        DxSimpleItem,
        DxButtonItem,
        DxCustomRule
    } from 'devextreme-vue/form';
    import DxButton from 'devextreme-vue/button';
    import { DxFileUploader } from 'devextreme-vue/file-uploader';
    import moment from 'moment';

    export default {
        name: "OperationLoading",
        props: ['rollingStockId', 'rollingStock', 'operationItem', 'Agent', 'getCheckListProcess'],
        components: {
            DxButton,
            DxForm,
            DxLabel,
            DxRequiredRule,
            DxSimpleItem,
            DxButtonItem,
            DxGroupItem,
            DxCustomRule,
            DxFileUploader,
            DxColCountByScreen
        },
        data() {
            return {
                okMessage: "",
                errorMessage: "",
                labelLocation: 'top',
                formData: {
                    arrivalTime: this.operationItem.result.arrivalTime,
                    startOperationTime: this.operationItem.result.startOperationTime,
                    endOperationTime: this.operationItem.result.endOperationTime,
                    departureTime: this.operationItem.result.departureTime,
                    note: this.operationItem.result.note,
                },
                isEdit: false,

                isAddPhoto4: false,
                formDataAddPhoto4: {},
                okMessageAddPhoto4: "",
                errorMessageAddPhoto4: "",
                photo4FileImageSource: '',
                photo4FileLable: '',
                photo4FileId: '',

                isAddPhoto2: false,
                formDataAddPhoto2: {},
                okMessageAddPhoto2: "",
                errorMessageAddPhoto2: "",
                photo2FileImageSource: '',
                photo2FileLable: '',
                photo2FileId: '',

                buttonOptionsSave: {
                    text: 'Сохранить',
                    type: 'default',
                    useSubmitBehavior: true
                },
            }
        },
        mounted() {
            this.loadFile(this.operationItem.result.foto4 ? this.operationItem.result.foto4.fileId : '', 'foto4');
            this.loadFile(this.operationItem.result.foto2 ? this.operationItem.result.foto2.fileId : '', 'foto2');
        },
        methods: {
            onSubmit: async function () {
                this.okMessage = "";
                this.errorMessage = "";
                this.isEdit = true;

                let {
                    arrivalTime,
                    startOperationTime,
                    endOperationTime,
                    departureTime,
                    note
                } = this.formData;

                if(arrivalTime) {
                    arrivalTime = moment(arrivalTime).format('YYYY-MM-DDTHH:mm:ss');
                }

                if(startOperationTime) {
                    startOperationTime = moment(startOperationTime).format('YYYY-MM-DDTHH:mm:ss');
                }

                if(endOperationTime) {
                    endOperationTime = moment(endOperationTime).format('YYYY-MM-DDTHH:mm:ss');
                }

                if(departureTime) {
                    departureTime = moment(departureTime).format('YYYY-MM-DDTHH:mm:ss');
                }

                httpClient.post(`v2/Carrier/RollingStocks/${this.rollingStockId}/CheckPoint/{${this.operationItem.result.routePointId}}?tokenId=${this.Agent.tokenId}`, {
                    arrivalTime: arrivalTime,
                    startOperationTime: startOperationTime,
                    endOperationTime: endOperationTime,
                    departureTime: departureTime,
                    note: note
                }).then(() => {
                    // if(this.getRollingStock) {
                    //     this.getRollingStock();
                    // }
                    //
                    // if(this.getRollingStocks) {
                    //     this.getRollingStocks();
                    // }

                    if(this.getCheckListProcess) {
                        this.getCheckListProcess();
                    }

                    this.okMessage = "Данные успешно сохранены!";

                    this.isEdit = false;
                }).catch(this.handleError);
            },
            handleError: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessage = errorMessage.replace('Параметр "arrivalTime", задан неверно! ', '');
                        this.errorMessage = this.errorMessage.replace('Параметр "startOperationTime", задан неверно! ', '');
                        this.errorMessage = this.errorMessage.replace('Параметр "endOperationTime", задан неверно! ', '');
                        this.errorMessage = this.errorMessage.replace('Параметр "departureTime", задан неверно! ', '');
                        this.errorMessage = this.errorMessage.replace('Параметр "note", задан неверно! ', '');
                    } else {
                        this.errorMessage = "Ошибка при сохранении данных. Проверьте указанные вами данные.";
                    }
                } else {
                    this.errorMessage = "Ошибка при сохранении данных. Проверьте указанные вами данные.";
                }

                this.isEdit = false;
                console.error(error);
            },
            downloadFile(fileId, fileName) {
                $('#btn-download-file-'+fileId).html('Загрузка...');
                $('#btn-download-file-'+fileId).prop('disabled', true);

                httpClient.get(`v2/FilesStarage/${fileId}/Download?tokenId=${this.Agent.tokenId}`)
                    .then((file) => {
                        let fileExt = fileName.split('.').pop();

                        const linkSource = `data:application/${fileExt};base64,${file.data}`;
                        const downloadLink = document.createElement('a');
                        document.body.appendChild(downloadLink);

                        downloadLink.href = linkSource;
                        downloadLink.target = '_self';
                        downloadLink.download = fileName;
                        downloadLink.click();

                        downloadLink.remove();

                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    })
                    .catch(() => {
                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    });
            },
            saveBase64(file, filePropertyName) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // use a regex to remove data url part
                    const base64String = reader.result
                        .replace("data:", "")
                        .replace(/^.+,/, "");

                    this.uploadFile(base64String, file.name, filePropertyName);
                };
                reader.readAsDataURL(file);
            },
            uploadFile(base64String, fileName, filePropertyName) {
                this.okMessage = "";
                this.errorMessage = "";
                fileName = this.$options.filters.encodeURL(fileName);

                let url = `v2/Carrier/RollingStocks/${this.rollingStockId}/CheckPoint/${this.operationItem.result.routePointId}/Upload/${filePropertyName}?fileName=${fileName}&tokenId=${this.Agent.tokenId}`;

                return httpClient
                    .post(url, `"${base64String}"`,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                    .then(() => { // response
                        this.getCheckListProcess();

                        switch (filePropertyName) {
                            case 'foto4':
                                this.isAddPhoto4 = false;
                                break;
                            case 'foto2':
                                this.isAddPhoto2 = false;
                                break;
                        }
                    }).catch(this.handleErrorSaveFile);
            },
            handleErrorSaveFile: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessage = errorMessage;
                    } else {
                        this.errorMessage = "Ошибка при сохранении файла. Проверьте указанные вами данные.";
                    }
                } else {
                    this.errorMessage = "Ошибка при сохранении файла. Проверьте указанные вами данные.";
                }

                this.isAddPhoto4 = false;
                this.isAddPhoto2 = false;
                console.error(error);
            },
            photo4FileValueChanged(args) {
                if(args.value[0]) {
                    this.formDataAddPhoto4.photo4File = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.photo4FileImageSource = fileReader.result;
                        this.photo4FileLable = file.name;

                        this.okMessageAddPhoto4 = "";
                        this.errorMessageAddPhoto4 = "";
                        this.isAddPhoto4 = true;

                        this.saveBase64(args.value[0], 'foto4');
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            photo2FileValueChanged(args) {
                if(args.value[0]) {
                    this.formDataAddPhoto2.photo2File = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.photo2FileImageSource = fileReader.result;
                        this.photo2FileLable = file.name;

                        this.okMessageAddPhoto2 = "";
                        this.errorMessageAddPhoto2 = "";
                        this.isAddPhoto2 = true;

                        this.saveBase64(args.value[0], 'foto2');
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            loadFile(fileId, name) {
                if(fileId != '') {
                    httpClient.get(`v2/FilesStarage/${fileId}/Download?tokenId=${this.Agent.tokenId}`)
                        .then((file) => {
                            switch (name) {
                                case 'foto4':
                                    this.photo4FileImageSource = `data:application/png;base64,${file.data}`;
                                    break;
                                case 'foto2':
                                    this.photo2FileImageSource = `data:application/png;base64,${file.data}`;
                                    break;
                            }
                        })
                }
            },
            formFieldDataChanged() { // e
                //console.log(e);
            },
            validateStartOperationTime1(e) {
                if(this.formData.endOperationTime && (e.value == '' || e.value == null)) {
                    return false;
                }

                return true;
            },
            validateEndOperationTime1(e) {
                if(this.formData.departureTime && (e.value == '' || e.value == null)) {
                    return false;
                }

                return true;
            },
            validateStartOperationTime2(e) {
                if(e.value != null) {
                    var d1 = new Date(this.formData.arrivalTime);
                    var d2 = new Date(e.value);

                    return d1 <= d2;
                }

                return true;
            },
            validateEndOperationTime2(e) {
                if(e.value != null) {
                    var d1 = new Date(this.formData.startOperationTime);
                    var d2 = new Date(e.value);

                    return d1 <= d2;
                }

                return true;
            },
            validateDepartureTime2(e) {
                if(e.value != null) {
                    var d1 = new Date(this.formData.endOperationTime);
                    var d2 = new Date(e.value);

                    return d1 <= d2;
                }

                return true;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .select-operation {
        -ms-content-zooming: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;
        padding: 0;
        outline: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #212529;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.35715;

        p {
            margin-bottom: 0.5rem;
        }

        .item-text {
            border-style: none none dashed none;
            background: 0 0;
            border-bottom: 1px dashed #ced4da;
            border-radius: 0;
        }
    }
</style>
