<template>
    <div>
        <div v-if="operationItem.result">
            <div class="row" v-if="!operationItem.result.primaryDocCaption">
                <div class="col-12">
                    <div id="form-container">
                        <div v-if="okMessage" class="alert alert-success mb-3" role="alert">
                            {{ okMessage }}
                        </div>
                        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                            <strong>Внимание!</strong> {{ errorMessage }}
                        </div>
                        <form class="edit-form" @submit.prevent="onSubmit">
                            <DxForm
                                    id="form"
                                    :col-count="1"
                                    :form-data="formData"
                                    :read-only="false"
                                    :show-colon-after-label="true"
                                    :show-validation-summary="true"
                                    :label-location="labelLocation"
                                    :disabled="isEdit"
                                    @field-data-changed="formFieldDataChanged"
                            >
                                <DxGroupItem
                                        :colCount="1"
                                >
                                    <DxSimpleItem
                                            data-field="contractId"
                                            :editor-options="{
                                                        dataSource: storeContracts,
                                                        displayExpr: 'contractCaption',
                                                        valueExpr: 'contractId',
                                                        searchEnabled: false,
                                                        showDataBeforeSearch: false,
                                                        showClearButton: true,
                                                        onValueChanged: handleContractValueChange
                                                    }"
                                            editor-type="dxSelectBox"
                                    >
                                        <DxLabel text="Договор"/>
                                        <DxRequiredRule message="Укажите Договор"/>
                                    </DxSimpleItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        :colCount="3"
                                >
                                    <DxColCountByScreen :xs="1" :sm="1"/>
                                    <DxSimpleItem
                                            data-field="driverId"
                                            :editor-options="{
                                                        dataSource: storeDrivers,
                                                        displayExpr: 'fullName',
                                                        valueExpr: 'driverId',
                                                        searchEnabled: true,
                                                        showDataBeforeSearch: true,
                                                        showClearButton: true,
                                                        onValueChanged: handleDriverValueChange
                                                    }"
                                            editor-type="dxSelectBox"
                                    >
                                        <DxLabel text="Водитель"/>
                                        <DxRequiredRule message="Укажите Водителя"/>
                                    </DxSimpleItem>
                                    <DxSimpleItem
                                            data-field="carId"
                                            :editor-options="{
                                                        dataSource: storeCars,
                                                        displayExpr: 'carCaption',
                                                        valueExpr: 'carId',
                                                        searchEnabled: true,
                                                        showDataBeforeSearch: true,
                                                        showClearButton: true,
                                                        onValueChanged: handleCarValueChange
                                                    }"
                                            editor-type="dxSelectBox"
                                    >
                                        <DxLabel text="Транспорт"/>
                                        <DxRequiredRule message="Укажите Транспорт"/>
                                    </DxSimpleItem>
                                    <DxSimpleItem
                                            data-field="trailerId"
                                            :editor-options="{
                                                        dataSource: storeTrailers,
                                                        displayExpr: 'carCaption',
                                                        valueExpr: 'carId',
                                                        searchEnabled: true,
                                                        showDataBeforeSearch: true,
                                                        showClearButton: true,
                                                        onValueChanged: handleTrailerValueChange
                                                    }"
                                            editor-type="dxSelectBox"
                                    >
                                        <DxCustomRule message="Укажите Прицеп" type="custom" :reevaluate="true" :validation-callback="validateTrailer" />
                                        <DxLabel text="Прицеп"/>
                                    </DxSimpleItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        :colCount="2"
                                >
                                    <DxColCountByScreen :xs="1" :sm="2"/>
                                    <DxSimpleItem
                                            data-field="hasSigned"
                                            :editor-options="{
                                                        text: 'Подписать документ',
                                                        onValueChanged: handlehasSignedValueChange,
                                                        elementAttr: {
                                                            class: 'pt-4'
                                                        }
                                                    }"
                                            editor-type="dxCheckBox"
                                    >
                                        <DxLabel :visible="false" text=""/>
                                    </DxSimpleItem>
                                    <DxButtonItem
                                            :button-options="buttonOptionsSave"
                                            cssClass="pt-4"
                                    />
                                </DxGroupItem>
                            </DxForm>
                        </form>
                    </div>
                </div>
            </div>
            <div v-if="operationItem.result.primaryDocCaption" class="row select-operation">
                <div class="col-12 col-md-9 col-lg-4 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Документ ЗПп:
                    </p>
                    <p class="item-text" v-if="operationItem.result.primaryDocCaption">
                        <button :id="'btn-primaryDocument-caption-'+operationItem.result.primaryDocId" type="button" class="btn btn-link btn-sm p-0 text-left" style="line-height: 0;" @click="openPrimaryDocument(operationItem.result.primaryDocId)">{{ operationItem.result.primaryDocCaption }}</button>
                    </p>
                    <p class="item-text" v-if="!operationItem.result.primaryDocCaption">
                        -
                    </p>
                </div>
                <div class="col-12 col-md-3 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Сумма:
                    </p>
                    <p class="item-text">
                        {{ operationItem.result.amountDisplay ? operationItem.result.amountDisplay : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-7 col-lg-3 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Подписант:
                    </p>
                    <p class="item-text">
                        {{ operationItem.result.signatoryName ? operationItem.result.signatoryName : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-5 col-lg-3 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mt-2 text-right">
                        <DxButton
                                v-if="isSing"
                                text="Обработка..."
                                type="default"
                                styling-mode="contained"
                                :disabled="true"
                                icon="rename"
                        />
                        <DxButton
                                v-if="!operationItem.result.isVerified && !isSing && operationItem.result.signatoryId && operationItem.result.signatoryId != Agent.contactId"
                                text="Отозвать подпись"
                                type="default"
                                styling-mode="contained"
                                :disabled="true"
                                icon="remove"
                        />
                        <DxButton
                                v-if="!operationItem.result.primaryDocId && operationItem.result.isVerified"
                                text="Подписать документ"
                                type="default"
                                styling-mode="contained"
                                :disabled="true"
                                icon="rename"
                        />
                        <DxButton
                                v-if="operationItem.result.primaryDocId && operationItem.result.isVerified"
                                text="Отозвать подпись"
                                type="default"
                                styling-mode="contained"
                                :disabled="true"
                                icon="remove"
                        />
                        <DxButton
                                v-if="!operationItem.result.isVerified && !isSing && !operationItem.result.signatoryId && operationItem.result.primaryDocId"
                                text="Подписать документ"
                                type="default"
                                styling-mode="contained"
                                @click="addSing"
                                icon="rename"
                        />
                        <DxButton
                                v-if="!operationItem.result.isVerified && !isSing && operationItem.result.signatoryId && operationItem.result.signatoryId == Agent.contactId && operationItem.result.primaryDocId"
                                text="Отозвать подпись"
                                type="default"
                                styling-mode="contained"
                                @click="delSing"
                                icon="remove"
                        />
                    </p>
                </div>
                <div class="col-12 col-md-7 col-lg-9 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Скан-копия документа:
                    </p>
                    <p v-if="!operationItem.result.scanFile" class="item-text">
                        -
                    </p>
                    <p v-if="operationItem.result.scanFile" class="item-text">
                        <button :id="'btn-download-file-'+operationItem.result.scanFile.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(operationItem.result.scanFile.fileId, operationItem.result.scanFile.fileName)">{{ operationItem.result.scanFile.fileName }}</button>
                    </p>
                </div>
                <div class="col-12 col-md-5 col-lg-3 mt-3 text-right">
                    <div id="form-container-add-scan">
                        <div v-if="okMessageAddScan" class="alert alert-success mb-3" role="alert">
                            {{ okMessageAddScan }}
                        </div>
                        <div v-if="errorMessageAddScan" class="alert alert-danger mb-3" role="alert">
                            <strong>Внимание!</strong> {{ errorMessageAddScan }}
                        </div>
                        <form class="add-scan-form">
                            <DxForm
                                    id="form-add-scan"
                                    :col-count="1"
                                    :form-data="formDataAddScan"
                                    :read-only="false"
                                    :show-colon-after-label="true"
                                    :show-validation-summary="true"
                                    :label-location="labelLocation"
                            >
                                <DxSimpleItem>
                                    <DxLabel :visible="false" text="" />
                                    <template #default>
                                        <div>
                                            <div class="row text-left text-md-right">
                                                <div class="col-12">
                                                    <DxButton
                                                            v-if="!isAddScan"
                                                            id="scanFile-add-external"
                                                            text="Загрузить скан-копию"
                                                            type="default"
                                                            styling-mode="contained"
                                                            :disabled="operationItem.result.isVerified"
                                                    />
                                                    <DxButton
                                                            v-if="isAddScan"
                                                            text="Загрузка..."
                                                            type="default"
                                                            styling-mode="contained"
                                                            :disabled="true"
                                                    />
                                                </div>
                                            </div>
                                            <DxFileUploader
                                                    id="scanFile"
                                                    dialog-trigger="#scanFile-add-external"
                                                    :multiple="false"
                                                    upload-mode="useForm"
                                                    :visible="false"
                                                    accept="image/jpeg,image/jpg,image/png,application/pdf"
                                                    @value-changed="scanFileValueChanged"
                                            />
                                        </div>
                                    </template>
                                </DxSimpleItem>
                            </DxForm>
                        </form>
                    </div>
                </div>
                <div class="col-12">
                    <hr>
                </div>
                <div class="col-12 col-md-7 col-lg-9 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mt-2 pb-0 mb-0" v-if="operationItem.result.verifiedCaption">
                        <i class="fas fa-award font-22 mr-2" style="color: #00afef"></i>{{ operationItem.result.verifiedCaption }}
                    </p>
                </div>
                <div class="col-12 col-md-5 col-lg-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mt-2 text-right">
                        <DxButton
                                v-if="loadingReports && !reports"
                                text="Загрузка..."
                                type="default"
                                styling-mode="contained"
                                :disabled="true"
                        />
                        <DxButton
                                v-if="!loadingReports && !reports"
                                text="Печать документа"
                                type="default"
                                styling-mode="contained"
                                :disabled="true"
                                icon="print"
                        />
                        <DxButton
                                v-if="loadingReports && reports"
                                text="Загрузка документа..."
                                type="default"
                                styling-mode="contained"
                                :disabled="true"
                                icon="print"
                        />
                        <DxDropDownButton
                                v-if="!loadingReports && reports"
                                text="Печать документа"
                                styling-mode="contained"
                                icon="print"
                                @item-click="clickBtnPrint"
                                :items="reports"
                                display-expr="reportCaption"
                                key-expr="code"
                                :element-attr="{class: 'dx-dropdownbutton-primary'}"
                        />
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DxDropDownButton from 'devextreme-vue/drop-down-button';
    import DxButton from 'devextreme-vue/button';
    import jQuery from 'jquery';
    let $ = jQuery;
    import {httpClient} from '../../../../shared/services';
    import localStore from '../../store';
    import {
        DxForm,
        DxGroupItem,
        DxColCountByScreen,
        DxLabel,
        DxRequiredRule,
        DxSimpleItem,
        DxButtonItem,
        DxCustomRule
        // DxPatternRule
    } from 'devextreme-vue/form';
    import { DxFileUploader } from 'devextreme-vue/file-uploader';
    import CustomStore from 'devextreme/data/custom_store';

    export default {
        name: "OperationAppCarrier",
        props: ['rollingStockId', 'operationItem', 'Agent', 'rollingStock', 'getRollingStocks', 'getRollingStock', 'getCheckListProcess'],
        components: {
            DxDropDownButton,
            DxButton,
            DxForm,
            DxLabel,
            DxRequiredRule,
            DxSimpleItem,
            DxButtonItem,
            DxGroupItem,
            DxCustomRule,
            DxFileUploader,
            DxColCountByScreen
        },
        data() {
            return {
                reports: null,
                loadingReports: false,
                isSing: false,
                isEdit: false,
                isAddScan: false,
                formData: {
                    hasSigned: false
                },
                formDataAddScan: {},
                labelLocation: 'top',
                okMessage: "",
                errorMessage: "",
                okMessageAddScan: "",
                errorMessageAddScan: "",
                storeContracts: null,
                storeDrivers: null,
                storeCars: null,
                storeTrailers: null,
                buttonOptionsSave: {
                    text: 'Сформировать ЗПп',
                    type: 'default',
                    useSubmitBehavior: true
                },
                selectCar: null
            }
        },
        mounted() {
            if(!this.operationItem.result.primaryDocId) {
                this.getContracts();

                window.tokenId = this.Agent.tokenId;

                this.storeCars = new CustomStore({
                    key: 'carId',
                    byKey: function (key) {
                        return httpClient.get(`v2/Carrier/Cars/${key}?tokenId=${window.tokenId}`);
                    },
                    load: (loadOptions) => {
                        let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

                        let filter = searchValue != '' ?
                            `&filter={"and":[{"carCaption":{"op":"con","value":"${searchValue}"}}, {"or":[{"specification":{"op":"eq","value":"spcTractor"}}, {"and":[{"specification":{"op":"eq","value":"spcCar"}}, {"rollingStockTypeId":{"op":"eq","value":"${this.rollingStock.rollingStockTypeId}"}}]}]}]}` :
                            `&filter={"or":[{"specification":{"op":"eq","value":"spcTractor"}}, {"and":[{"specification":{"op":"eq","value":"spcCar"}}, {"rollingStockTypeId":{"op":"eq","value":"${this.rollingStock.rollingStockTypeId}"}}]}]}`;

                        return httpClient.get(`v2/Carrier/Cars?tokenId=${this.Agent.tokenId}${filter}`)
                            .then((data) => {
                                return {
                                    key: 'carId',
                                    data: data.data.items,
                                    totalCount: data.totals,
                                    summary: data.totals,
                                    groupCount: data.count,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                });

                this.storeTrailers = new CustomStore({
                    key: 'carId',
                    byKey: function () {
                        return {};
                    },
                    load: (loadOptions) => {
                        let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

                        let filter = searchValue != '' ?
                            `&filter={"and":[{"carCaption":{"op":"con","value":"${searchValue}"}}, {"specification":{"op":"eq","value":"spcTrailer"}}, {"rollingStockTypeId":{"op":"eq","value":"${this.rollingStock.rollingStockTypeId}"}}]}` :
                            `&filter={"and":[{"specification":{"op":"eq","value":"spcTrailer"}}, {"rollingStockTypeId":{"op":"eq","value":"${this.rollingStock.rollingStockTypeId}"}}]}`;

                        return httpClient.get(`v2/Carrier/Cars?tokenId=${this.Agent.tokenId}${filter}`)
                            .then((data) => {
                                return {
                                    key: 'carId',
                                    data: data.data.items,
                                    totalCount: data.totals,
                                    summary: data.totals,
                                    groupCount: data.count,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                });

                this.storeDrivers = new CustomStore({
                    key: 'driverId',
                    byKey: function () {
                        return {};
                    },
                    load: (loadOptions) => {
                        let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

                        let filter = searchValue != '' ? `&filter={"fullName":{"op":"con","value":"${searchValue}"}}` : '';

                        return httpClient.get(`v2/Carrier/Drivers?tokenId=${this.Agent.tokenId}${filter}`)
                            .then((data) => {
                                return {
                                    key: 'driverId',
                                    data: data.data.items,
                                    totalCount: data.totals,
                                    summary: data.totals,
                                    groupCount: data.count,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                });

                this.isSing = false;
                this.isEdit = false;
            } else {
                this.getReports();

                if(localStore.state.operationSelect != null) {
                    $('#collapseOperation-'+localStore.state.operationSelect).collapse('show');
                }
            }
        },
        methods: {
            getContracts() {
                httpClient.get(`v2/Contracts?tokenId=${this.Agent.tokenId}`)
                    .then((data) => {
                        this.storeContracts = data.data.filter(contract => contract.status == 'csSigned' && contract.contractKind == 'ДП-АВТО');
                    })
                    .catch(() => {
                        throw 'Data Loading Error';
                    });
            },
            getReports() {
                if(this.operationItem.result && this.operationItem.result.primaryDocId) {
                    this.loadingReports = true;

                    httpClient.get(`v2/PrimaryDocs/${this.operationItem.result.primaryDocId}/Reports?tokenId=${this.Agent.tokenId}`)
                        .then((reports) => {
                            this.reports = reports.data;
                            this.loadingReports = false;
                        })
                        .catch(this.handleErrorLoadingReports);
                } else {
                    this.loadingReports = false;
                }
            },
            handleErrorLoadingReports: function (error) {
                console.error(error);
                this.loadingReports = false;
            },
            downloadFile(fileId, fileName) {
                $('#btn-download-file-'+fileId).html('Загрузка...');
                $('#btn-download-file-'+fileId).prop('disabled', true);

                httpClient.get(`v2/FilesStarage/${fileId}/Download?tokenId=${this.Agent.tokenId}`)
                    .then((file) => {
                        const linkSource = `data:application/png;base64,${file.data}`;
                        const downloadLink = document.createElement('a');
                        document.body.appendChild(downloadLink);

                        downloadLink.href = linkSource;
                        downloadLink.target = '_self';
                        downloadLink.download = fileName;
                        downloadLink.click();

                        downloadLink.remove();

                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    })
                    .catch(() => {
                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    });
            },
            clickBtnPrint(e) {
                this.printDoc(e.itemData.code);
            },
            printDoc(reportCode) {
                this.loadingReports = true;

                httpClient.get(`v2/PrimaryDocs/${this.operationItem.result.primaryDocId}/Reports/${reportCode}?tokenId=${this.Agent.tokenId}`)
                    .then((pdf) => {
                        let byteCharacters = atob(pdf.data);
                        let byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        let byteArray = new Uint8Array(byteNumbers);
                        let file = new Blob([byteArray], {type: 'application/pdf;base64'});
                        let fileURL = URL.createObjectURL(file);
                        let wo = window.open(fileURL);
                        wo.print();

                        this.loadingReports = false;
                    })
                    .catch(this.handleErrorPrintDoc);
            },
            handleErrorPrintDoc: function (error) {
                console.error(error);
                this.loadingReports = false;
            },
            addSing() {
                this.isSing = true;

                httpClient.post(`v2/PrimaryDocs/${this.operationItem.result.primaryDocId}/Sing?tokenId=${this.Agent.tokenId}`)
                    .then(() => {
                        this.getCheckListProcess();

                        this.isSing = false;
                    })
                    .catch(this.handleErrorSing);
            },
            delSing() {
                this.isSing = true;

                httpClient.post(`v2/PrimaryDocs/${this.operationItem.result.primaryDocId}/Unsing?tokenId=${this.Agent.tokenId}`)
                    .then(() => {
                        this.getCheckListProcess();

                        this.isSing = false;
                    })
                    .catch(this.handleErrorSing);
            },
            handleErrorSing: function (error) {
                console.error(error);
                this.isSing = false;
            },
            onSubmit: async function () {
                this.okMessage = "";
                this.errorMessage = "";
                this.isEdit = true;

                let {
                    contractId,
                    driverId,
                    carId,
                    trailerId,
                    hasSigned
                } = this.formData;

                if(trailerId != undefined) {
                    trailerId = `&trailerId=${trailerId}`
                } else {
                    trailerId = '';
                }

                httpClient.post(`v2/Carrier/RollingStocks/${this.rollingStockId}/regAppCarrier?contractId=${contractId}&driverId=${driverId}&carId=${carId}${trailerId}&hasSigned=${hasSigned}&tokenId=${this.Agent.tokenId}`).then((response) => {
                    if(this.getRollingStock) {
                        this.getRollingStock();
                    }

                    if(this.getRollingStocks) {
                        this.getRollingStocks();
                    }

                    //this.getCheckListProcess();
                    this.operationItem.result.message = response.data.message;
                    this.operationItem.result.status = response.data.status;
                    this.operationItem.result.primaryDocId = response.data.primaryDocId;
                    this.operationItem.result.primaryDocCaption = response.data.primaryDocCaption;
                    this.operationItem.result.amountDisplay = response.data.amountDisplay;
                    this.operationItem.result.signatoryId = response.data.signatoryId;
                    this.operationItem.result.signatoryName = response.data.signatoryName;
                    this.operationItem.result.scanFile = response.data.scanFile;
                    this.operationItem.result.isVerified = response.data.isVerified;
                    this.operationItem.result.verifiedCaption = response.data.verifiedCaption;
                    this.printDoc('appCarrier-def');

                    this.getReports();
                    //this.isEdit = false;
                }).catch(this.handleErrorReg);
            },
            saveBase64(file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // use a regex to remove data url part
                    const base64String = reader.result
                        .replace("data:", "")
                        .replace(/^.+,/, "");

                    this.uploadFile(base64String, file.name);
                };
                reader.readAsDataURL(file);
            },
            uploadFile(base64String, fileName) {
                fileName = this.$options.filters.encodeURL(fileName);

                return httpClient
                    .post(`v2/PrimaryDocs/${this.rollingStock.primaryDocumentId}/Upload?fileName=${fileName}&tokenId=${this.Agent.tokenId}`, `"${base64String}"`,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                    .then(() => {
                        this.getCheckListProcess();
                        this.isAddScan = false;
                    }).catch(this.handleErrorSaveFile);
            },
            handleErrorSaveFile: function (error) {
                this.errorMessageAddScan = "Ошибка при сохранении файла. Попробуйте еще раз.";
                console.error(error);
                this.isAddScan = false;
            },
            handleErrorReg(error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessage = errorMessage.replace('Параметр "contractId", задан неверно! ', '');
                        this.errorMessage = this.errorMessage.replace('Параметр "trailerId", задан неверно! ', '');
                    } else {
                        this.errorMessage = "Ошибка при формировании ЗПп. Проверьте указанные вами данные и попробуйте еще раз.";
                    }
                } else {
                    this.errorMessage = "Ошибка при формировании ЗПп. Проверьте указанные вами данные и попробуйте еще раз.";
                }

                this.isEdit = false;
                console.error(error.response);
            },
            formFieldDataChanged() { // e
                //console.log(e);
            },
            handleContractValueChange() { // e
                //this.formData.model = '';
            },
            handleDriverValueChange() {

            },
            handlehasSignedValueChange() {

            },
            handleCarValueChange(e) {
                if(e.value) {
                    this.storeCars.byKey(e.value).then((car) => {
                        this.selectCar = car.data;
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    this.selectCar = null
                }
            },
            handleTrailerValueChange() {

            },
            validateTrailer(e) {
                if(this.selectCar && this.selectCar.specification == 'spcTractor') {
                    return e.value == null ? false : true;
                }

                return true;
            },
            scanFileValueChanged(args) {
                if(args.value[0]) {
                    this.okMessageAddScan = "";
                    this.errorMessageAddScan = "";
                    this.isAddScan = true;

                    this.saveBase64(args.value[0]);
                }
            },
            openPrimaryDocument(primaryDocumentId) {
                let route = this.$router.resolve({name: 'PrimaryDocsPrimaryDocument', params: {primaryDocumentId: primaryDocumentId}});
                window.open(route.href, '_blank');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .select-operation {
        -ms-content-zooming: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;
        padding: 0;
        outline: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #212529;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.35715;

        p {
            margin-bottom: 0.5rem;
        }

        .item-text {
            border-style: none none dashed none;
            background: 0 0;
            border-bottom: 1px dashed #ced4da;
            border-radius: 0;
        }
    }
</style>
