<template>
    <div>
        <div v-if="operationItem.result">
            <div v-if="!operationItem.result.freeElements && operationItem.result.invoices.length == 0">
                <div class="row">
                    <div class="col-12">
                        На данный момент счетов нет.
                    </div>
                </div>
            </div>
            <div v-for="(invoice, index) in operationItem.result.invoices" :key="invoice.invoiceId" class="table-responsive-md">
                <hr v-if="index > 0">
                <div v-if="invoice.status != 'pdsEditing'" class="row select-operation">
                    <div class="col-12 mb-3">
                        <h5>Счет</h5>
                    </div>
                    <div class="col-12 col-md-6 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2">
                            Номер:
                        </p>
                        <p class="item-text">
                            {{ invoice.number }}
                        </p>
                    </div>
                    <div class="col-12 col-md-6 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2">
                            Дата:
                        </p>
                        <p class="item-text">
                            {{ invoice.invoiceDate | moment("DD.MM.YYYY") }}
                        </p>
                    </div>
                    <div class="col-12 col-md-8 col-lg-6 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2">
                            Описание:
                        </p>
                        <p class="item-text">
                            {{ invoice.description ? invoice.description : '-' }}
                        </p>
                    </div>
                    <div class="col-12 col-md-4 col-lg-2 mb-3 mb-md-3 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2 mb-0">
                            Состояние:
                        </p>
                        <p class="item-text" v-html="getStatus(invoice.status)"></p>
                    </div>
                    <div class="col-12 mb-3 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2" style="margin-bottom: 0.2rem;">
                            Скан-копия:
                        </p>
                        <p class="item-text" v-if="invoice.scanFile">
                            <button :id="'btn-download-file-'+invoice.scanFile.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(invoice.scanFile.fileId, invoice.scanFile.fileName)">{{ invoice.scanFile.fileName }}</button>
                        </p>
                        <p class="item-text" v-if="!invoice.scanFile">
                            Нет файла
                        </p>
                    </div>
                    <div class="col-12 col-md-3 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2">
                            Срок оплаты:
                        </p>
                        <p class="item-text" v-if="invoice.checkPayingDate">
                            {{ invoice.checkPayingDate | moment("DD.MM.YYYY") }}
                        </p>
                        <p class="item-text" v-if="!invoice.checkPayingDate">
                            Не указан
                        </p>
                    </div>
                    <div class="col-12 col-md-3 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2">
                            Сумма:
                        </p>
                        <p class="item-text">
                            {{ invoice.amountDisplay ? invoice.amountDisplay : '-' }}
                        </p>
                    </div>
                    <div class="col-12 col-md-3 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2">
                            Оплачено:
                        </p>
                        <p class="item-text">
                            {{ invoice.postingAmountDisplay ? invoice.postingAmountDisplay : '-' }}
                        </p>
                    </div>
                    <div class="col-12 col-md-3 mb-3 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2">
                            Остаток:
                        </p>
                        <p class="item-text">
                            {{ invoice.balanceAmountDisplay ? invoice.balanceAmountDisplay : '-' }}
                        </p>
                    </div>
                    <div class="col-12 mb-3">
                        <h5>АВР (Документ основания)</h5>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2">
                            Номер:
                        </p>
                        <p class="item-text">
                            {{ invoice.basisDocument && invoice.basisDocument.basisNumber ? invoice.basisDocument.basisNumber : '-' }}
                        </p>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2">
                            Дата:
                        </p>
                        <p class="item-text">
                            {{ invoice.basisDocument && invoice.basisDocument.basisDate ? invoice.basisDocument.basisDate : '-' | moment("DD.MM.YYYY") }}
                        </p>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2">
                            Вид документа:
                        </p>
                        <p class="item-text">
                            {{ invoice.basisDocument && invoice.basisDocument.basisDocumentName ? invoice.basisDocument.basisDocumentName : '-' }}
                        </p>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 d-flex flex-row flex-md-column justify-content-start">
                        <p class="mr-2" style="margin-bottom: 0.2rem;">
                            Скан-копия:
                        </p>
                        <p class="item-text" v-if="invoice.basisDocument">
                            <button :id="'btn-download-file-'+invoice.basisDocument.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(invoice.basisDocument.fileId, invoice.basisDocument.fileName)">{{ invoice.basisDocument.fileName }}</button>
                        </p>
                        <p class="item-text" v-if="!invoice.basisDocument">
                            Нет файла
                        </p>
                    </div>
                </div>
                <div v-if="invoice.status == 'pdsEditing'" class="row select-operation">
                    <div class="col-12 mb-2">
                        <h5>Счет</h5>
                    </div>
                    <div class="col-12 mb-4">
                        <div :id="'edit-form-container-'+invoice.invoiceId">
                            <div v-if="okMessageEditInvoices[invoice.invoiceId]" class="alert alert-success mb-3" role="alert">
                                {{ okMessageEditInvoices[invoice.invoiceId] }}
                            </div>
                            <div v-if="errorMessageEditInvoices[invoice.invoiceId]" class="alert alert-danger mb-3" role="alert">
                                <strong>Внимание!</strong> {{ errorMessageEditInvoices[invoice.invoiceId] }}
                            </div>
                            <form class="edit-form" @submit.prevent="onSubmitEditInvoice(invoice.invoiceId)">
                                <DxForm
                                        :id="'edit-form-'+invoice.invoiceId"
                                        :col-count="1"
                                        :form-data="formDataEditInvoices[invoice.invoiceId]"
                                        :read-only="false"
                                        :show-colon-after-label="true"
                                        :show-validation-summary="true"
                                        :label-location="labelLocation"
                                        :disabled="isEditInvoice && isEditInvoices[invoice.invoiceId]"
                                >
                                    <DxGroupItem
                                            :colCount="6"
                                    >
                                        <DxColCountByScreen :xs="1" :sm="1"/>
                                        <DxSimpleItem
                                                data-field="number"
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Номер"/>
                                            <DxRequiredRule message="Укажите Номер счета"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="date"
                                                :editor-options="{type: 'date', displayFormat: 'dd.MM.yyyy'}"
                                                editor-type="dxDateBox"
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Дата"/>
                                            <DxRequiredRule message="Укажите Дату счета"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :colSpan="3"
                                        >
                                            <DxLabel text="Описание"/>
                                            <template #default>
                                                <div class="select-operation">
                                                    <p class="item-text pt-2">
                                                        {{ invoice.description ? invoice.description : '-' }}
                                                    </p>
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Состояние"/>
                                            <template #default>
                                                <div class="select-operation">
                                                    <p class="item-text" v-html="getStatus(invoice.status)"></p>
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                    <DxGroupItem
                                            cssClass="mt-3"
                                    >
                                        <DxSimpleItem>
                                            <DxLabel text="Скан-копия"/>
                                            <DxRequiredRule message="Выберите скан-копию счета"/>
                                            <template #default>
                                                <div>
                                                    <div class="row">
                                                        <div class="col-12 col-md-9 mb-3">
                                                            <p class="item-text pt-2" v-if="selectEditInvoiceId == invoice.invoiceId && fileEditInvoicesId[invoice.invoiceId] != '' && fileEditInvoicesLabel[invoice.invoiceId] != ''">
                                                                <button :id="'btn-download-file-'+invoice.scanFile.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(invoice.scanFile.fileId, invoice.scanFile.fileName)">{{ invoice.scanFile.fileName }}</button>
                                                            </p>
                                                            <p class="item-text pt-3" v-if="selectEditInvoiceId == invoice.invoiceId && fileEditInvoicesId[invoice.invoiceId] == '' && fileEditInvoicesLabel[invoice.invoiceId] != ''">
                                                                <span>{{ fileEditInvoicesLabel[invoice.invoiceId] }}</span>
                                                            </p>
                                                            <span v-if="fileEditInvoicesLabel[invoice.invoiceId] == ''" class="text-danger">Файл не выбран</span>
                                                        </div>
                                                        <div class="col-12 col-md-3 text-right">
                                                            <DxButton
                                                                    :id="'fileInvoice-edit-external-'+invoice.invoiceId"
                                                                    text="Выбрать файл"
                                                                    type="default"
                                                                    styling-mode="contained"
                                                            />
                                                        </div>
                                                    </div>
                                                    <DxFileUploader
                                                            :id="'fileEditInvoice-'+invoice.invoiceId"
                                                            :dialog-trigger="'#fileInvoice-edit-external-'+invoice.invoiceId"
                                                            :multiple="false"
                                                            upload-mode="useForm"
                                                            :visible="false"
                                                            accept="image/jpeg,image/jpg,image/png,application/pdf"
                                                            @value-changed="fileEditInvoiceValueChanged"
                                                    />
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                    <DxGroupItem
                                            :colCount="4"
                                    >
                                        <DxColCountByScreen :xs="1" :sm="1"/>
                                        <DxSimpleItem
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Срок оплаты"/>
                                            <template #default>
                                                <div class="select-operation">
                                                    <p class="item-text pt-2" v-if="invoice.checkPayingDate">
                                                        {{ invoice.checkPayingDate | moment("DD.MM.YYYY") }}
                                                    </p>
                                                    <p class="item-text pt-2" v-if="!invoice.checkPayingDate">
                                                        Не указан
                                                    </p>
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Сумма"/>
                                            <template #default>
                                                <div class="select-operation">
                                                    <p class="item-text pt-2">
                                                        {{ invoice.amountDisplay ? invoice.amountDisplay : '-' }}
                                                    </p>
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Оплачено"/>
                                            <template #default>
                                                <div class="select-operation">
                                                    <p class="item-text pt-2">
                                                        {{ invoice.postingAmountDisplay ? invoice.postingAmountDisplay : '-' }}
                                                    </p>
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Остаток"/>
                                            <template #default>
                                                <div class="select-operation">
                                                    <p class="item-text pt-2">
                                                        {{ invoice.balanceAmountDisplay ? invoice.balanceAmountDisplay : '-' }}
                                                    </p>
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                    <DxGroupItem
                                            :colCount="2"
                                    >
                                        <DxColCountByScreen :xs="2" :sm="2"/>
                                        <DxSimpleItem
                                                :colSpan="1"
                                                cssClass="pt-4 d-inline-block"
                                        >
                                            <DxLabel :visible="false"/>
                                            <template #default>
                                                <div>
                                                    <DxButton
                                                            text="Удалить счет"
                                                            type="danger"
                                                            styling-mode="contained"
                                                            @click="onDelInvoice(invoice.invoiceId)"
                                                    />
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                        <DxButtonItem
                                                :button-options="buttonOptionsSave"
                                                cssClass="pt-4"
                                        />
                                    </DxGroupItem>
                                </DxForm>
                            </form>
                        </div>
                    </div>
                    <div class="col-12 mb-3">
                        <h5>АВР (Документ основания)</h5>
                    </div>
                    <div class="col-12 mb-3">
                        <div :id="'edit-form-doc-container-'+invoice.invoiceId">
                            <div v-if="okMessageEditBasisDocuments[invoice.invoiceId]" class="alert alert-success mb-3" role="alert">
                                {{ okMessageEditBasisDocuments[invoice.invoiceId] }}
                            </div>
                            <div v-if="errorMessageEditBasisDocuments[invoice.invoiceId]" class="alert alert-danger mb-3" role="alert">
                                <strong>Внимание!</strong> {{ errorMessageEditBasisDocuments[invoice.invoiceId] }}
                            </div>
                            <form class="edit-form" @submit.prevent="onSubmitEditBasisDocument(invoice.invoiceId)">
                                <DxForm
                                        :id="'edit-form-doc-'+invoice.invoiceId"
                                        :col-count="1"
                                        :form-data="formDataEditBasisDocuments[invoice.invoiceId]"
                                        :read-only="false"
                                        :show-colon-after-label="true"
                                        :show-validation-summary="true"
                                        :label-location="labelLocation"
                                        :disabled="isEditBasisDocument && isEditBasisDocuments[invoice.invoiceId]"
                                >
                                    <DxGroupItem
                                            :colCount="6"
                                    >
                                        <DxColCountByScreen :xs="1" :sm="1"/>
                                        <DxSimpleItem
                                                data-field="basisNumber"
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Номер"/>
                                            <DxRequiredRule message="Укажите Номер основания"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="basisDate"
                                                :editor-options="{type: 'date', displayFormat: 'dd.MM.yyyy'}"
                                                editor-type="dxDateBox"
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Дата"/>
                                            <DxRequiredRule message="Укажите Дату основания"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :colSpan="1"
                                                data-field="basisDocumentName"
                                                :editor-options="{
                                                    dataSource: basisDocumentNames,
                                                    acceptCustomValue: true,
                                                    onCustomItemCreating: onCustomItemBasisDocumentCreating
                                                }"
                                                editor-type="dxSelectBox"
                                        >
                                            <DxLabel text="Вид документа"/>
                                            <DxRequiredRule message="Укажите Вид документа"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :colSpan="3"
                                        >
                                            <DxLabel text="Скан-копия"/>
                                            <DxRequiredRule message="Выберите скан-копию счета"/>
                                            <template #default>
                                                <div>
                                                    <div class="row">
                                                        <div class="col-12 col-md-8">
                                                            <p class="item-text pt-2" v-if="selectEditBasisDocumentId == invoice.invoiceId && fileEditBasisDocumentsId[invoice.invoiceId] != '' && fileEditBasisDocumentsLabel[invoice.invoiceId] != ''">
                                                                <button :id="'btn-download-file-'+invoice.basisDocument.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(invoice.basisDocument.fileId, invoice.basisDocument.fileName)">{{ invoice.basisDocument.fileName }}</button>
                                                            </p>
                                                            <p class="item-text pt-3" v-if="selectEditBasisDocumentId == invoice.invoiceId && fileEditBasisDocumentsId[invoice.invoiceId] == '' && fileEditBasisDocumentsLabel[invoice.invoiceId] != ''">
                                                                <span>{{ fileEditBasisDocumentsLabel[invoice.invoiceId] }}</span>
                                                            </p>
                                                            <span v-if="fileEditBasisDocumentsLabel[invoice.invoiceId] == ''" class="text-danger">Файл не выбран</span>
                                                        </div>
                                                        <div class="col-12 col-md-4 text-right">
                                                            <DxButton
                                                                    :id="'fileBasisDocument-edit-external-'+invoice.invoiceId"
                                                                    text="Выбрать файл"
                                                                    type="default"
                                                                    styling-mode="contained"
                                                            />
                                                        </div>
                                                    </div>
                                                    <DxFileUploader
                                                            :id="'fileEditBasisDocument-'+invoice.invoiceId"
                                                            :dialog-trigger="'#fileBasisDocument-edit-external-'+invoice.invoiceId"
                                                            :multiple="false"
                                                            upload-mode="useForm"
                                                            :visible="false"
                                                            accept="image/jpeg,image/jpg,image/png,application/pdf"
                                                            @value-changed="fileEditBasisDocumentValueChanged"
                                                    />
                                                </div>
                                            </template>
                                        </DxSimpleItem>

                                    </DxGroupItem>
                                    <DxGroupItem
                                            :colCount="2"
                                    >
                                        <DxColCountByScreen :xs="2" :sm="2"/>
                                        <DxSimpleItem
                                                :colSpan="1"
                                                cssClass="pt-4 d-inline-block"
                                        >
                                            <DxLabel :visible="false"/>
                                            <template #default>
                                                <div>
                                                    <DxButton
                                                            v-if="fileEditBasisDocumentsId[invoice.invoiceId]"
                                                            text="Удалить АВР"
                                                            type="danger"
                                                            styling-mode="contained"
                                                            @click="onDelBasisDocument(invoice.invoiceId)"
                                                    />
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                        <DxButtonItem
                                                :button-options="buttonOptionsSave"
                                                cssClass="pt-4"
                                        />
                                    </DxGroupItem>
                                </DxForm>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="operationItem.result.freeElements">
                <div class="row">
                    <div v-if="operationItem.result.invoices.length > 0" class="col-12 mt-3">
                        <hr>
                    </div>
                    <div class="col-12 mb-2">
                        <h5>Добавление счета</h5>
                    </div>
                    <div class="col-12 pb-3">
                        <div id="add-form-container">
                            <div v-if="okMessageAddInvoice" class="alert alert-success mb-3" role="alert">
                                {{ okMessageAddInvoice }}
                            </div>
                            <div v-if="errorMessageAddInvoice" class="alert alert-danger mb-3" role="alert">
                                <strong>Внимание!</strong> {{ errorMessageAddInvoice }}
                            </div>
                            <form class="add-form" @submit.prevent="onSubmitAddInvoice">
                                <DxForm
                                        id="add-form"
                                        :col-count="1"
                                        :form-data="formDataAddInvoice"
                                        :read-only="false"
                                        :show-colon-after-label="true"
                                        :show-validation-summary="true"
                                        :label-location="labelLocation"
                                        :disabled="isAddInvoice"
                                >
                                    <DxGroupItem
                                            :colCount="4"
                                    >
                                        <DxColCountByScreen :xs="1" :sm="1"/>
                                        <DxSimpleItem
                                                data-field="number"
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Номер"/>
                                            <DxRequiredRule message="Укажите Номер счета"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="date"
                                                :editor-options="{type: 'date', displayFormat: 'dd.MM.yyyy'}"
                                                editor-type="dxDateBox"
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Дата"/>
                                            <DxRequiredRule message="Укажите Дату счета"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :colSpan="2"
                                        >
                                            <DxLabel text="Скан-копия"/>
                                            <DxRequiredRule message="Выберите скан-копию счета"/>
                                            <template #default>
                                                <div>
                                                    <div class="row">
                                                        <div class="col-12 col-md-6">
                                                            <span v-if="fileAddInvoiceLabel">{{ fileAddInvoiceLabel }}</span>
                                                            <span v-if="!fileAddInvoiceLabel" class="text-danger">Файл не выбран</span>
                                                        </div>
                                                        <div class="col-12 col-md-6 text-right">
                                                            <DxButton
                                                                    id="fileInvoice-add-external"
                                                                    text="Выбрать файл"
                                                                    type="default"
                                                                    styling-mode="contained"
                                                            />
                                                        </div>
                                                    </div>
                                                    <DxFileUploader
                                                            id="fileAddInvoice"
                                                            dialog-trigger="#fileInvoice-add-external"
                                                            :multiple="false"
                                                            upload-mode="useForm"
                                                            :visible="false"
                                                            accept="image/jpeg,image/jpg,image/png,application/pdf"
                                                            @value-changed="fileAddInvoiceValueChanged"
                                                    />
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                    <DxButtonItem
                                            :button-options="buttonOptionsAdd"
                                            cssClass="pt-4"
                                    />
                                </DxForm>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import jQuery from 'jquery';
    let $ = jQuery;
    import {httpClient} from '../../../../shared/services';
    import DxButton from 'devextreme-vue/button';
    import {
        DxForm,
        DxButtonItem,
        DxGroupItem,
        DxColCountByScreen,
        DxSimpleItem,
        DxLabel,
        DxRequiredRule
    } from 'devextreme-vue/form';
    import { DxFileUploader } from 'devextreme-vue/file-uploader';
    import moment from 'moment';

    export default {
        name: "OperationAccountDoc",
        props: ['rollingStockId', 'operationItem', 'Agent', 'getCheckListProcess'],
        components: {
            DxButton,
            DxForm,
            DxButtonItem,
            DxGroupItem,
            DxColCountByScreen,
            DxSimpleItem,
            DxLabel,
            DxRequiredRule,
            DxFileUploader
        },
        data() {
            return {
                okMessageAddInvoice: "",
                errorMessageAddInvoice: "",
                formDataAddInvoice: {},
                isAddInvoice: false,
                labelLocation: 'top',
                buttonOptionsSave: {
                    text: 'Сохранить',
                    type: 'default',
                    useSubmitBehavior: true
                },
                buttonOptionsAdd: {
                    text: 'Добавить',
                    type: 'default',
                    useSubmitBehavior: true
                },
                fileAddInvoiceImageSource: "",
                fileAddInvoiceLabel: "",

                okMessageEditInvoices: [],
                errorMessageEditInvoices: [],

                isEditInvoice: false,
                formDataEditInvoices: [],
                isEditInvoices: [],

                fileEditInvoicesId: [],
                fileEditInvoicesLabel: [],
                fileEditInvoicesImageSource: [],
                balanceAccountIdEditInvoices: [],

                okMessageEditBasisDocuments: [],
                errorMessageEditBasisDocuments: [],

                isEditBasisDocument: false,
                formDataEditBasisDocuments: [],
                isEditBasisDocuments: [],

                fileEditBasisDocumentsId: [],
                fileEditBasisDocumentsLabel: [],
                fileEditBasisDocumentsImageSource: [],
                balanceAccountIdEditBasisDocuments: [],

                selectEditInvoiceId: '',
                selectEditBasisDocumentId: '',

                basisDocumentNames: ["АВР", "ЭАВР", "СФ", "ЭСФ"]
            }
        },
        watch: {
            operationItem: function () {
                this.initData();
            }
        },
        created() {
            this.operationItem.result.invoices.forEach(function (invoice) {
                this.isEditInvoices[invoice.invoiceId] = false;
                this.okMessageEditInvoices[invoice.invoiceId] = "";
                this.errorMessageEditInvoices[invoice.invoiceId] = "";

                this.isEditBasisDocuments[invoice.invoiceId] = false;
                this.okMessageEditBasisDocuments[invoice.invoiceId] = "";
                this.errorMessageEditBasisDocuments[invoice.invoiceId] = "";
            }, this);

            this.initData();
        },
        mounted() {

        },
        methods: {
            initData() {
                this.operationItem.result.invoices.forEach(function (invoice) {
                    this.formDataEditInvoices[invoice.invoiceId] = {
                        number: invoice.number,
                        date: invoice.invoiceDate
                    };

                    this.balanceAccountIdEditInvoices[invoice.invoiceId] = invoice.balanceAccountId;

                    this.balanceAccountIdEditBasisDocuments[invoice.invoiceId] = invoice.balanceAccountId;

                    if(invoice.scanFile) {
                        this.fileEditInvoicesId[invoice.invoiceId] = invoice.scanFile.fileId;
                        this.fileEditInvoicesLabel[invoice.invoiceId] = invoice.scanFile.fileName;
                        this.fileEditInvoicesImageSource[invoice.invoiceId] = "";
                        this.selectEditInvoiceId = invoice.invoiceId;
                    } else {
                        this.fileEditInvoicesId[invoice.invoiceId] = "";
                        this.fileEditInvoicesLabel[invoice.invoiceId] = "";
                        this.fileEditInvoicesImageSource[invoice.invoiceId] = "";
                        this.selectEditInvoiceId = "";
                    }

                    if(invoice.basisDocument) {
                        this.fileEditBasisDocumentsId[invoice.invoiceId] = invoice.basisDocument.fileId;
                        this.fileEditBasisDocumentsLabel[invoice.invoiceId] = invoice.basisDocument.fileName;
                        this.fileEditBasisDocumentsImageSource[invoice.invoiceId] = "";

                        this.formDataEditBasisDocuments[invoice.invoiceId] = {
                            basisNumber: invoice.basisDocument.basisNumber,
                            basisDate: invoice.basisDocument.basisDate,
                            basisDocumentName: invoice.basisDocument.basisDocumentName,
                        };

                        this.addBasisDocuments(invoice.basisDocument.basisDocumentName);

                        this.selectEditBasisDocumentId = invoice.invoiceId;
                    } else {
                        this.fileEditBasisDocumentsId[invoice.invoiceId] = "";
                        this.fileEditBasisDocumentsLabel[invoice.invoiceId] = "";
                        this.fileEditBasisDocumentsImageSource[invoice.invoiceId] = "";
                        this.selectEditBasisDocumentId = "";

                        this.formDataEditBasisDocuments[invoice.invoiceId] = {};
                    }
                }, this);
            },
            onSubmitAddInvoice: async function () {
                if(this.fileAddInvoiceImageSource != "") {
                    this.okMessageAddInvoice = "";
                    this.errorMessageAddInvoice = "";
                    this.isAddInvoice = true;

                    let {
                        number,
                        date
                    } = this.formDataAddInvoice;

                    date = moment(date).format('YYYY-MM-DD');
                    let file = this.fileAddInvoiceImageSource.replace("data:", "").replace(/^.+,/, "");
                    let fileName = this.$options.filters.encodeURL(this.fileAddInvoiceLabel);

                    httpClient.post(`v2/Carrier/RollingStocks/${this.rollingStockId}/UploadInvoice?fileName=${fileName}&number=${number}&date=${date}&tokenId=${this.Agent.tokenId}`,
                        `"${file}"`,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).then(() => {
                        this.getCheckListProcess();

                        this.okMessageAddInvoice = "";
                        this.errorMessageAddInvoice = "";
                        this.formDataAddInvoice = {};
                        this.fileAddInvoiceImageSource = "";
                        this.fileAddInvoiceLabel = "";
                        this.isAddInvoice = false;
                    }).catch(this.handleErrorAddInvoice);
                } else {
                    this.errorMessageAddInvoice = "Вы не выбрали скан-копию счета.";
                }
            },
            onSubmitEditInvoice: async function (invoiceId) {
                window.invoiceId = invoiceId;

                this.okMessageEditInvoices[invoiceId] = "";
                this.errorMessageEditInvoices[invoiceId] = "";
                this.isEditInvoices[invoiceId] = true;
                this.isEditInvoice = true;

                let {
                    number,
                    date
                } = this.formDataEditInvoices[invoiceId];

                date = moment(date).format('YYYY-MM-DD');
                let file = this.fileEditInvoicesImageSource[invoiceId].replace("data:", "").replace(/^.+,/, "");
                let fileName = this.$options.filters.encodeURL(this.fileEditInvoicesLabel[invoiceId]);

                file != "" ? httpClient.post(`v2/Finances/${this.balanceAccountIdEditInvoices[invoiceId]}/Invoices/${invoiceId}?fileName=${fileName}&number=${number}&date=${date}&tokenId=${this.Agent.tokenId}`,
                    `"${file}"`,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(() => {
                        this.okMessageEditInvoices[invoiceId] = "Счет успешно сохранен";

                        this.getCheckListProcess();

                        this.isEditInvoices[invoiceId] = false;
                        this.isEditInvoice = false;
                }).catch(this.handleErrorEditInvoice) :
                    httpClient.put(`v2/Finances/${this.balanceAccountIdEditInvoices[invoiceId]}/Invoices/${invoiceId}?number=${number}&date=${date}&tokenId=${this.Agent.tokenId}`).then(() => {
                        this.okMessageEditInvoices[invoiceId] = "Счет успешно сохранен";

                        this.getCheckListProcess();

                        this.isEditInvoices[invoiceId] = false;
                        this.isEditInvoice = false;
                    }).catch(this.handleErrorEditInvoice);
            },
            onSubmitEditBasisDocument: async function (invoiceId) {
                window.invoiceId = invoiceId;

                this.okMessageEditBasisDocuments[invoiceId] = "";
                this.errorMessageEditBasisDocuments[invoiceId] = "";
                this.isEditBasisDocuments[invoiceId] = true;
                this.isEditBasisDocument = true;

                let {
                    basisNumber,
                    basisDate,
                    basisDocumentName
                } = this.formDataEditBasisDocuments[invoiceId];

                basisDate = moment(basisDate).format('YYYY-MM-DD');
                let file = this.fileEditBasisDocumentsImageSource[invoiceId].replace("data:", "").replace(/^.+,/, "");
                let fileName = this.$options.filters.encodeURL(this.fileEditBasisDocumentsLabel[invoiceId]);

                file != "" ? httpClient.post(`v2/Finances/${this.balanceAccountIdEditBasisDocuments[invoiceId]}/InvoiceBasis/${invoiceId}?fileName=${fileName}&basisNumber=${basisNumber}&basisDate=${basisDate}&basisDocumentName=${basisDocumentName}&tokenId=${this.Agent.tokenId}`,
                    `"${file}"`,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(() => {
                        this.okMessageEditBasisDocuments[invoiceId] = "АВР (Документ основания) успешно сохранено";

                        this.getCheckListProcess();

                        this.isEditBasisDocuments[invoiceId] = false;
                        this.isEditBasisDocument = false;
                    }).catch(this.handleErrorEditBasisDocument) :
                    httpClient.put(`v2/Finances/${this.balanceAccountIdEditBasisDocuments[invoiceId]}/InvoiceBasis/${invoiceId}?basisNumber=${basisNumber}&basisDate=${basisDate}&basisDocumentName=${basisDocumentName}&tokenId=${this.Agent.tokenId}`).then(() => {
                        this.okMessageEditBasisDocuments[invoiceId] = "АВР (Документ основания) успешно сохранено";

                        this.getCheckListProcess();

                        this.isEditBasisDocuments[invoiceId] = false;
                        this.isEditBasisDocument = false;
                    }).catch(this.handleErrorEditBasisDocument);
            },
            handleErrorAddInvoice: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessage = errorMessage.replace('Параметр "date", задан неверно! ', '');
                        this.errorMessage = this.errorMessage.replace('Параметр "number", задан неверно! ', '');
                    } else {
                        this.errorMessage = "Ошибка при создании счета. Проверьте указанные вами данные.";
                    }
                } else {
                    this.errorMessage = "Ошибка при создании счета. Проверьте указанные вами данные.";
                }

                this.isEdit = false;
                console.error(error);
            },
            handleErrorEditInvoice: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessageEditInvoices[window.invoiceId] = errorMessage.replace('Параметр "date", задан неверно! ', '');
                        this.errorMessageEditInvoices[window.invoiceId] = this.errorMessageEditInvoices[window.invoiceId].replace('Параметр "number", задан неверно! ', '');
                    } else {
                        this.errorMessageEditInvoices[window.invoiceId] = "Ошибка при редактировании счета. Проверьте указанные вами данные.";
                    }
                } else {
                    this.errorMessageEditInvoices[window.invoiceId] = "Ошибка при редактировании счета. Проверьте указанные вами данные.";
                }

                this.isEditInvoices[window.invoiceId] = false;
                this.isEditInvoice = false;
                console.error(error);
            },
            handleErrorEditBasisDocument: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessageEditBasisDocuments[window.invoiceId] = errorMessage.replace('Параметр "date", задан неверно! ', '');
                        this.errorMessageEditBasisDocuments[window.invoiceId] = this.errorMessageEditBasisDocuments[window.invoiceId].replace('Параметр "number", задан неверно! ', '');
                        this.errorMessageEditBasisDocuments[window.invoiceId] = this.errorMessageEditBasisDocuments[window.invoiceId].replace('Параметр "basisDocumentName", задан неверно! ', '');
                    } else {
                        this.errorMessageEditBasisDocuments[window.invoiceId] = "Ошибка при редактировании АВР. Проверьте указанные вами данные.";
                    }
                } else {
                    this.errorMessageEditBasisDocuments[window.invoiceId] = "Ошибка при редактировании АВР. Проверьте указанные вами данные.";
                }

                this.isEditBasisDocuments[window.invoiceId] = false;
                this.isEditBasisDocument = false;
                console.error(error);
            },
            fileAddInvoiceValueChanged(args) {
                if(args.value[0]) {
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.fileAddInvoiceImageSource = fileReader.result;
                        this.fileAddInvoiceLabel = file.name;

                        this.okMessageAddInvoice = "";
                        this.errorMessageAddInvoice = "";
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            fileEditInvoiceValueChanged(args) {
                this.selectEditInvoiceId = "";
                if(args.value[0]) {
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        let invoiceId = args.element.id.replace("fileEditInvoice-", "");

                        this.fileEditInvoicesId[invoiceId] = "";
                        this.fileEditInvoicesImageSource[invoiceId] = fileReader.result;
                        this.fileEditInvoicesLabel[invoiceId] = file.name;
                        this.selectEditInvoiceId = invoiceId;
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            fileEditBasisDocumentValueChanged(args) {
                this.selectEditBasisDocumentId = "";
                if(args.value[0]) {
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        let invoiceId = args.element.id.replace("fileEditBasisDocument-", "");

                        this.fileEditBasisDocumentsId[invoiceId] = "";
                        this.fileEditBasisDocumentsImageSource[invoiceId] = fileReader.result;
                        this.fileEditBasisDocumentsLabel[invoiceId] = file.name;
                        this.selectEditBasisDocumentId = invoiceId;
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            downloadFile(fileId, fileName) {
                $('#btn-download-file-'+fileId).html('Загрузка...');
                $('#btn-download-file-'+fileId).prop('disabled', true);

                httpClient.get(`v2/FilesStarage/${fileId}/Download?tokenId=${this.Agent.tokenId}`)
                    .then((file) => {
                        let fileExt = fileName.split('.').pop();

                        const linkSource = `data:application/${fileExt};base64,${file.data}`;
                        const downloadLink = document.createElement('a');
                        document.body.appendChild(downloadLink);

                        downloadLink.href = linkSource;
                        downloadLink.target = '_self';
                        downloadLink.download = fileName;
                        downloadLink.click();

                        downloadLink.remove();

                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    })
                    .catch(() => {
                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    });
            },
            getStatus(status) {
                switch (status) {
                    case 'pdsEditing':
                        return '<span class="badge badge-warning font-12">В обработке</span>';

                    case 'pdsAccounted':
                        return '<span class="badge badge-success font-12">Обработан</span>';

                    case 'pdsReject':
                        return '<span class="badge badge-danger font-12">Анулирован</span>';
                }

                return '';
            },
            addBasisDocuments(obj) {
                if(!this.basisDocumentNames.includes(obj)) {
                    this.basisDocumentNames.push(obj);
                }
            },
            onCustomItemBasisDocumentCreating(e) {
                this.addBasisDocuments(e.text);

                return e.text;
            },
            onDelInvoice(invoiceId) {
                let isConfirm = confirm("Вы действительно хотите удалить этот счет?");
                if(isConfirm) {
                    window.invoiceId = invoiceId;

                    this.okMessageEditInvoices[invoiceId] = "";
                    this.errorMessageEditInvoices[invoiceId] = "";
                    this.isEditInvoices[invoiceId] = true;
                    this.isEditInvoice = true;

                    httpClient.delete(`v2/Finances/${this.balanceAccountIdEditInvoices[invoiceId]}/Invoices/${invoiceId}?tokenId=${this.Agent.tokenId}`).then(() => {
                        this.getCheckListProcess();

                        this.isEditInvoices[invoiceId] = false;
                        this.isEditInvoice = false;
                    }).catch(this.handleErrorDelInvoice);
                }
            },
            onDelBasisDocument(invoiceId) {
                let isConfirm = confirm("Вы действительно хотите удалить этот АВР (Документ основания)?");
                if(isConfirm) {
                    window.invoiceId = invoiceId;

                    this.okMessageEditBasisDocuments[invoiceId] = "";
                    this.errorMessageEditBasisDocuments[invoiceId] = "";
                    this.isEditBasisDocuments[invoiceId] = true;
                    this.isEditBasisDocument = true;

                    httpClient.delete(`v2/Finances/${this.balanceAccountIdEditBasisDocuments[invoiceId]}/InvoiceBasis/${invoiceId}?tokenId=${this.Agent.tokenId}`).then(() => {
                        this.getCheckListProcess();

                        this.isEditBasisDocuments[invoiceId] = false;
                        this.isEditBasisDocument = false;
                    }).catch(this.handleErrorDelBasisDocument);
                }
            },
            handleErrorDelInvoice: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessageEditInvoices[window.invoiceId] = errorMessage;
                    } else {
                        this.errorMessageEditInvoices[window.invoiceId] = "Ошибка при удалении АВР.";
                    }
                } else {
                    this.errorMessageEditInvoices[window.invoiceId] = "Ошибка при удалении АВР.";
                }

                this.isEditInvoices[window.invoiceId] = false;
                this.isEditInvoice = false;
                console.error(error);
            },
            handleErrorDelBasisDocument: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessageEditBasisDocuments[window.invoiceId] = errorMessage;
                    } else {
                        this.errorMessageEditBasisDocuments[window.invoiceId] = "Ошибка при удалении АВР.";
                    }
                } else {
                    this.errorMessageEditBasisDocuments[window.invoiceId] = "Ошибка при удалении АВР.";
                }

                this.isEditBasisDocuments[window.invoiceId] = false;
                this.isEditBasisDocument = false;
                console.error(error);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .select-operation {
        -ms-content-zooming: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;
        padding: 0;
        outline: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #212529;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.35715;

        p {
            margin-bottom: 0.5rem;
        }

        .item-text {
            border-style: none none dashed none;
            background: 0 0;
            border-bottom: 1px dashed #ced4da;
            border-radius: 0;
        }
    }
</style>
