<template>
    <div>
        <vue-title title="Перевозки | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container pt-4 pb-2">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Перевозки</h1>
            </div>
        </div>
        <div id="rolling-stocks-favorites" class="rolling-stocks-favorites">
            <div class="pb-3">
                <div class="container">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="title"><i class="fas fa-bookmark mr-2"></i>Избранные</div>
                        <div class="total">Всего: <span class="text-primary">{{ rollingStocksFavorites ? rollingStocksFavorites.totals : 0 }}</span></div>
                    </div>
                </div>
            </div>
            <div class="bg-white pt-4 pb-2">
                <div class="container" v-if="!rollingStocksFavorites">
                    <DxLoadIndicator
                            id="medium-indicator"
                            :height="20"
                            :width="20"
                    />
                    Загрузка
                </div>
                <div class="container" v-if="rollingStocksFavorites">
                    <div v-if="rollingStocksFavorites.totals == 0">
                        <strong>Ничего не найдено.</strong>
                    </div>
                    <div class="row" v-if="rollingStocksFavorites.totals > 0">
                        <div class="col-12 mb-4" v-for="rollingStocksFavoriteItem in rollingStocksFavorites.items" :key="rollingStocksFavoriteItem.rollingStockId">
                            <div class="card h-100 shadow-sm">
                                <div class="card-header bg-white" @click="openFavoriteDetail(rollingStocksFavoriteItem.rollingStockId)" style="cursor: pointer;">
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            <small class="mb-2">{{ rollingStocksFavoriteItem.acn }}</small>
                                        </div>
                                        <div class="col-6 text-right">
                                            <i class="fas fa-chevron-up pt-1" :id="'favoriteChevron-'+rollingStocksFavoriteItem.rollingStockId"></i>
                                        </div>
                                    </div>
                                    <div>
                                        {{ rollingStocksFavoriteItem.routeCaption }}
                                    </div>
                                </div>
                                <div :id="'card-'+rollingStocksFavoriteItem.rollingStockId" class="card-body d-none">
                                    <RollingStock
                                            :key="'detail-'+rollingStocksFavoriteItem.rollingStockId"
                                            v-if="openRollingStocksFavorites.includes(''+rollingStocksFavoriteItem.rollingStockId+'')"
                                            :rollingStockId="rollingStocksFavoriteItem.rollingStockId"
                                            :agent="Agent"
                                            :getRollingStocks="getRollingStocks"
                                            :getFavorites="getFavorites"
                                    />
                                </div>
                                <div class="card-footer text-muted">
                                    <div class="row">
                                        <div class="col-6">
                                            <button type="button" class="btn btn-outline-danger btn-sm" @click="delFavorite(rollingStocksFavoriteItem.rollingStockId)" title="Убрать из избранных"><i class="far fa-times-circle mr-2"></i>Забыть</button>
                                        </div>
                                        <div class="col-6 pt-1 font-14 text-right text-muted">
                                            <i class="far fa-calendar-alt mr-2"></i> {{ rollingStocksFavoriteItem.startTime | moment("DD.MM.YYYY") }}<span v-if="rollingStocksFavoriteItem.closedDate"> - {{ rollingStocksFavoriteItem.closedDate | moment("DD.MM.YYYY") }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <paginate
                            :page-count="this.rollingStocksFavorites ? Math.ceil(this.rollingStocksFavorites.totals / this.take) : 0"
                            :click-handler="clickPagination"
                            :prev-text="'  '"
                            :next-text="'  '"
                            :container-class="'pagination justify-content-center'"
                            :page-class="'page-item'"
                            :page-link-class="'page-link'"
                            :prev-class="'page-item'"
                            :next-class="'page-item'"
                            :prev-link-class="'page-link'"
                            :next-link-class="'page-link'"
                            :active-class="'active'"
                            >
                    </paginate>
                </div>
            </div>
        </div>
        <div id="rolling-stocks-all" class="rolling-stocks-all">
            <div class="pt-3 pb-3">
                <div class="container">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="title">Все</div>
                    </div>
                </div>
            </div>
            <div class="container pb-4">
                <DxDataGrid
                        id="gridContainerRollingStocks"
                        :ref="dataGridRefKeyRollingStocks"
                        :data-source="storeRollingStocks"
                        :columns="columnsRollingStocks"
                        :show-borders="true"
                        :row-alternation-enabled="true"
                        :show-row-lines="true"
                        :customize-columns="customizeColumnsRollingStocks"
                        :paging="{pageSize: pageSize, enabled: true}"
                        :pager="pager"
                        :remote-operations="true"
                        :filter-row="{visible: true}"
                        @row-click="onRowClickRollingStocks"
                >
                    <DxSelection mode="single"/>
                    <DxMasterDetail
                            :enabled="true"
                            template="masterDetailTemplate"
                    />
                    <template #masterDetailTemplate="{ data }">
                        <div class="row">
<!--                            <div class="col-12 col-lg-6">-->
<!--                                <CargoLot-->
<!--                                        v-if="selectedRowKey == data.key"-->
<!--                                        :cargoLotId="data.data.rollingStockId"-->
<!--                                        :offerIsLocked="true"-->
<!--                                        :agent="Agent"-->
<!--                                />-->
<!--                            </div>-->
                            <div class="col-12 col-lg-12">
                                <RollingStock
                                        v-if="selectedRowKey == data.key"
                                        :rollingStockId="data.key"
                                        :agent="Agent"
                                        :getRollingStocks="getRollingStocks"
                                />
                            </div>
                        </div>
                    </template>
                    <template #acn-cell-template="{ data }">
                        <div>
                            <div>{{data.data.acn}}</div>
                            <div><button type="button" class="btn btn-link btn-sm pt-2 pb-0 px-0" @click="addFavorite(data.data.rollingStockId)"><i class="fas fa-bookmark mr-2"></i>В избранные</button></div>
                        </div>
                    </template>
                </DxDataGrid>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import jQuery from 'jquery';
    let $ = jQuery;
    import headerRoutes from './header-routes-2';
    import {mapGetters} from 'vuex';
    import localStore from './store';
    import {httpClient} from '../../shared/services';
    import {Header, Footer} from '../../shared/components';
    import { DxDataGrid, DxSelection, DxMasterDetail } from 'devextreme-vue/data-grid';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import CustomStore from 'devextreme/data/custom_store';
    import moment from 'moment';

    //import CargoLot from './detail/CargoLot';
    import RollingStock from './detail/RollingStock';

    const dataGridRefKeyRollingStocks = "rolling-stocks-data-grid";

    export default {
        name: "CarrierRollingStocks",
        components: {
            Header,
            Footer,
            DxDataGrid,
            DxSelection,
            DxMasterDetail,
            DxLoadIndicator,
            RollingStock,
            //CargoLot
        },
        data() {
            return {
                headerRoutes,
                dataGridRefKeyRollingStocks,
                selectedRowKey: "",
                storeRollingStocks: null,
                columnsRollingStocks: [
                    {
                        caption: 'Номер',
                        dataField: 'acn',
                        allowSorting: true,
                        width: 140,
                        allowHeaderFiltering: false,
                        cellTemplate: "acn-cell-template",
                    },
                    {
                        caption: 'Дата начала',
                        dataField: 'startTime',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: true,
                        allowResizing: false,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Дата ЗП',
                        dataField: 'closedDate',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: true,
                        allowResizing: false,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Начало маршрута',
                        dataField: 'firstLocality.localityDisplay',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Окончание маршрута',
                        dataField: 'lastLocality.localityDisplay',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Вид ПС',
                        dataField: 'rollingStockTypeName',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Транспорт',
                        dataField: 'carCaption',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Водитель',
                        dataField: 'driverName',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                ],
                pager: {
                    allowedPageSizes: [5, 10, 15, 30],
                    showInfo: true,
                    infoText: 'Страница {0} из {1} (Всего {2})',
                    showNavigationButtons: true,
                    showPageSizeSelector: true,
                    visible: true
                },
                pageSize: 10,
                rollingStocksFavorites: null,
                skeep: 0,
                take: 6,
                openRollingStocksFavorites: [],
            }
        },
        created() {
            window.addEventListener("resize", this.resizeEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeEventHandler);
        },
        mounted() {
            this.getFavorites();
            this.getRollingStocks();
        },
        methods: {
            getFavorites(skeep = this.skeep, take = this.take) {
                //$('html, body').animate({scrollTop:0}, '300');

                //this.rollingStocksFavorites = null;
                localStore.dispatch('getRollingStocksFavorites', {tokenId: this.Agent.tokenId, skeep: skeep, take: take}).then(() => {
                    this.rollingStocksFavorites = localStore.state.rollingStocksFavorites;
                });
            },
            getRollingStocks() {
                this.storeRollingStocks = new CustomStore({
                    key: 'rollingStockId',
                    byKey: async function (key) {
                        if(key) {
                            console.log(key);
                        }
                    },
                    load: (loadOptions) => {

                        let tokenId = this.Agent.tokenId;
                        let sortStr = '';
                        let filterStr = '';
                        let takeStr = '';
                        let skeepStr = '';

                        if(loadOptions.take) {
                            takeStr = `&take=${loadOptions.take}`;
                        }

                        if(loadOptions.skip) {
                            skeepStr = `&skeep=${loadOptions.skip}`;
                        }

                        if (loadOptions.sort) {
                            let sort = loadOptions.sort[0];
                            sortStr = `&order_field=${sort.selector}&order_desc=${sort.desc}`;
                        }

                        if (loadOptions.filter) {

                            if(loadOptions.filter.columnIndex != undefined) { // Фильтрация только по одному полю
                                switch (loadOptions.filter.columnIndex) {
                                    case 1:
                                    case 2:
                                        if (Array.isArray(loadOptions.filter[0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter);
                                        } else {
                                            let dateFilter = moment(loadOptions.filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                            filterStr = this.getFilterItem(loadOptions.filter[0], loadOptions.filter[1], dateFilter);
                                        }
                                        break;
                                    default:
                                        if (Array.isArray(loadOptions.filter[0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter);
                                        } else {
                                            filterStr = this.getFilterItem(loadOptions.filter[0], loadOptions.filter[1], loadOptions.filter[2]);
                                        }
                                        break;
                                }

                                filterStr = filterStr ? `&filter={"and":[${filterStr}]}` : '';
                            } else { // Фильтрация по нескольким полям
                                if(loadOptions.filter[0] != 'rollingStockId') {
                                    if(loadOptions.filter[0] != '!') {
                                        filterStr = this.getFilterGroup(loadOptions.filter);
                                    } else {
                                        if (Array.isArray(loadOptions.filter[1][0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter[1], true);
                                        } else {
                                            filterStr = this.getFilterItem(loadOptions.filter[1][0], '<>', loadOptions.filter[1][2]);
                                        }
                                    }

                                    filterStr = filterStr ? `&filter=${filterStr}` : '';
                                }
                            }
                        }

                        return httpClient.get(`v2/Carrier/RollingStocks?tokenId=${tokenId}${takeStr}${skeepStr}${sortStr}${filterStr}`)
                            .then((rss) => {
                                return {
                                    key: 'rollingStockId',
                                    data: rss.data.items,
                                    totalCount: rss.data.totals,
                                    summary: rss.data.totals,
                                    groupCount: rss.data.count,
                                    userData: rss.data,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                });
            },
            getFilterOp(op) {
                switch (op) {
                    case '>':
                        return 'gt';
                    case '>=':
                        return 'gte';
                    case '<':
                        return 'lt';
                    case '=<':
                    case '<=':
                        return 'lte';
                    case '=':
                        return 'eq';
                    case '<>':
                        return 'neq';
                    case 'contains':
                        return 'con';
                    case 'notcontains':
                        return 'ncon';
                    case 'startswith':
                        return 'sw';
                    case 'endswith':
                        return 'ew';
                }
            },
            getFilterItem(field_name, op, value) {
                if(field_name == 'amount') {
                    value = `${value}`;
                } else {
                    switch (value) {
                        case true:
                        case false:
                            value = `${value}`;
                            break;
                        default:
                            value = `"${value}"`;
                            break;
                    }
                }

                return `{"${field_name}":{"op":"${this.getFilterOp(op)}","value":${value}}}`;
            },
            getFilterGroup(group_filters, isNo = false) {
                let condition = '';
                let filter_items = '';

                group_filters.forEach(function (filter) {
                    if (Array.isArray(filter)) {
                        if(Array.isArray(filter[0])) {
                            filter_items += this.getFilterGroup(filter) + ',';
                        } else {
                            if(filter[0] == '!') {
                                if(Array.isArray(filter[1][0])) {
                                    filter_items += this.getFilterGroup(filter[1], true) + ',';
                                } else {
                                    filter_items += this.getFilterItem(filter[1][0], '<>', filter[1][2]) + ',';
                                }
                            } else {
                                switch (group_filters.columnIndex) {
                                    case 1:
                                    case 2:
                                        var dateFilter1 = moment(filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                        filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), dateFilter1) + ',';
                                        break;
                                    default:
                                        switch (filter.columnIndex) {
                                            case 1:
                                            case 2:
                                                var dateFilter2 = moment(filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                                filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), dateFilter2) + ',';
                                                break;
                                            default:
                                                filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), filter[2]) + ',';
                                                break;
                                        }
                                        break;
                                }
                            }
                        }
                    } else {
                        condition = filter;
                    }
                }, this);

                filter_items = filter_items.replace(/,$/, "");

                return `{"${condition}":[${filter_items}]}`;
            },
            changeColumns(columns) {
                if(columns.length > 0) {
                    columns[0].width = '140px';
                    columns[1].visible = true;
                    columns[2].visible = true;
                    columns[3].visible = true;
                    columns[4].visible = true;
                    columns[5].visible = true;
                    columns[6].visible = true;
                    columns[7].visible = true;

                    switch (this.$mq) {
                        case 'sm':
                            columns[0].width = '140px';
                            columns[1].visible = false;
                            columns[2].visible = false;
                            columns[3].visible = false;
                            columns[4].visible = false;
                            columns[5].visible = false;
                            columns[6].visible = true;
                            columns[7].visible = false;
                            break;
                        case 'md':
                        case 'lg':
                            columns[0].width = '140px';
                            columns[1].visible = true;
                            columns[2].visible = false;
                            columns[3].visible = true;
                            columns[4].visible = false;
                            columns[5].visible = false;
                            columns[6].visible = true;
                            columns[7].visible = true;
                            break;
                    }
                }

                return columns;
            },
            customizeColumnsRollingStocks(columns) { //columns
                this.changeColumns(columns);
            },
            onRowClickRollingStocks(e) {
                if(e.rowType == "data" && !e.event.originalEvent.currentTarget.className.includes('dx-button') && !e.event.originalEvent.originalEvent.srcElement.className.includes('btn btn-link')) {
                    if(this.selectedRowKey != e.key) {
                        if(this.selectedRowKey != "") {
                            this.dataGrid.collapseRow(this.selectedRowKey);
                            this.selectedRowKey = "";
                        }

                        this.selectedRowKey = e.key;

                        this.dataGrid.expandRow(this.selectedRowKey);
                    } else {
                        this.dataGrid.collapseRow(this.selectedRowKey);

                        this.selectedRowKey = "";
                    }
                }
            },
            resizeEventHandler() { // e
                this.dataGrid.option('columns', this.changeColumns(this.dataGrid.option().columns));
                this.dataGrid.repaint();
            },
            clickPagination: function (pageNum) {
                this.skeep = (pageNum - 1) * this.take;
                this.getFavorites();
            },
            openFavoriteDetail(rollingStockId) {
                let id = 'card-'+rollingStockId;
                if($('#'+id).hasClass('d-none')) {
                    this.openRollingStocksFavorites.push(''+rollingStockId+'');

                    $('#'+id).removeClass('d-none');
                    $('#'+id+'-btn').html('Свернуть');
                } else {
                    $('#'+id).addClass('d-none');
                    $('#'+id+'-btn').html('Подробно');

                    const index = this.openRollingStocksFavorites.indexOf(rollingStockId);
                    if (index > -1) {
                        this.openRollingStocksFavorites.splice(index, 1);
                    }
                }

                this.changeFavoriteChevron(rollingStockId);
            },
            changeFavoriteChevron(id) {
                if($('#favoriteChevron-'+id).hasClass('fa-chevron-up')) {
                    $('#favoriteChevron-'+id).removeClass('fa-chevron-up');
                    $('#favoriteChevron-'+id).addClass('fa-chevron-down');
                } else {
                    $('#favoriteChevron-'+id).removeClass('fa-chevron-down');
                    $('#favoriteChevron-'+id).addClass('fa-chevron-up');
                }
            },
            delFavorite(rollingStockId) {
                //this.rollingStocksFavorites = null;

                httpClient.delete(`v2/Carrier/RollingStocks/Favorites/${rollingStockId}?tokenId=${this.Agent.tokenId}`).then(() => {
                    this.getFavorites();
                }).catch(this.handleErrorDelFavorite);
            },
            addFavorite(rollingStockId) {
                $('html, body').animate({scrollTop:0}, '300');
                //this.rollingStocksFavorites = null;

                httpClient.post(`v2/Carrier/RollingStocks/Favorites/${rollingStockId}?tokenId=${this.Agent.tokenId}`).then(() => {
                    this.getFavorites();
                }).catch(this.handleErrorAddFavorite);
            },
            handleErrorAddFavorite: function (error) {
                this.getFavorites();
                console.error(error);
            },
            handleErrorDelFavorite: function (error) {
                this.getFavorites();
                console.error(error);
            },
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ]),
            dataGrid: function() {
                return this.$refs[dataGridRefKeyRollingStocks].instance;
            }
        },
    }
</script>

<style lang="scss" scoped>
    .rolling-stocks-favorites,
    .rolling-stocks-all {
        .title {
            font-size: var(--size-26);
        }
    }
</style>
