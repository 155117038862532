<template>
    <div>
        <div v-if="!rollingStock">
            <DxLoadIndicator
                    id="medium-indicator"
                    :height="20"
                    :width="20"
            />
            Загрузка
        </div>
        <div v-if="rollingStock" class="table-responsive-md">
            <div class="row select-rolling-stock mb-3">
                <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Номер:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.acn ? rollingStock.acn : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Дата начала:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.startTime ? rollingStock.startTime : '-' | moment("DD.MM.YYYY") }}
                    </p>
                </div>
                <div class="col-12 col-md-4 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Дата ЗП:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.closedDate ? rollingStock.closedDate : '-' | moment("DD.MM.YYYY") }}
                    </p>
                </div>
                <div class="col-12 col-md-6 col-lg-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Начало маршрута:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.firstLocality.localityDisplay ? rollingStock.firstLocality.localityDisplay : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Окончание маршрута:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.lastLocality.localityDisplay ? rollingStock.lastLocality.localityDisplay : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-6 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Водитель:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.driverName ? rollingStock.driverName : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-6 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Транспорт:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.carCaption ? rollingStock.carCaption : '-' }}
                    </p>
                </div>
                <div class="col-12">
                    <p class="mr-2">
                        Описание груза:
                    </p>
                    <p>
                        <button class="btn btn-outline-primary btn-block text-left shadow-sm" type="button" data-toggle="collapse" data-target="#collapseCargo" aria-expanded="false" aria-controls="collapseCargo" @click="openCargo(rollingStock.rollingStockId)">
                            <i class="fas fa-chevron-right mr-2" :id="'cargoChevron'+rollingStock.rollingStockId"></i>{{ rollingStock.cargo && rollingStock.cargo.descriptionOfCargo ? rollingStock.cargo.descriptionOfCargo : '-' }}
                        </button>
                    </p>
                </div>
            </div>
            <div class="row select-rolling-stock collapse" id="collapseCargo">
                <div class="col-12 col-md-3 col-lg-1 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Вес (т):
                    </p>
                    <p class="item-text">
                        {{ rollingStock.cargo && rollingStock.cargo.weight ? rollingStock.cargo.weight/1000 : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Объем (куб.м.):
                    </p>
                    <p class="item-text">
                        {{ rollingStock.cargo && rollingStock.cargo.volume ? rollingStock.cargo.volume : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Кол-во мест:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.cargo && rollingStock.cargo.quantity ? rollingStock.cargo.quantity : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-3 col-lg-3 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Вид упаковки:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.cargo && rollingStock.cargo.packageType ? rollingStock.cargo.packageType : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Объяв. стоимоть:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.cargo && rollingStock.cargo.declareAmount ? rollingStock.cargo.declareAmount : '-' | formatMoney2 }}
                    </p>
                </div>
                <div class="col-12 col-md-2 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Валюта:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.cargo && rollingStock.cargo.currencyName ? rollingStock.cargo.currencyName : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-12 col-lg-9 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Особые условия перевозки:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.cargo && rollingStock.cargo.additionCarriageTerm ? rollingStock.cargo.additionCarriageTerm : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-3 col-lg-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Способ погрузки:
                    </p>
                    <p class="item-text">
                        {{ rollingStock.cargo && rollingStock.cargo.loadingType ? rollingStock.cargo.loadingType : '-' }}
                    </p>
                </div>
            </div>
            <div class="row select-rolling-stock mt-3">
                <div class="col-12">
                    <h5 class="mr-2">
                        Чек лист:
                    </h5>
                    <hr>
                    <div v-if="operationsLoad">
                        <DxLoadIndicator
                                id="medium-indicator-operations"
                                :height="20"
                                :width="20"
                        />
                        Загрузка
                    </div>
                    <div v-if="!operationsLoad && !rsCheckListProcessOperations">
                        <strong>Ничего не найдено.</strong>
                    </div>
                    <div v-if="!operationsLoad && rsCheckListProcessOperations">
                        <div class="mb-3" v-for="operationItem in rsCheckListProcessOperations" :key="operationItem.id+'-'+rollingStock.rollingStockId">
                            <div class="btn-group btn-block" role="group">
                                <button type="button" class="btn bg-white border-right-0"
                                        data-toggle="collapse" :data-target="'#collapseOperation-'+operationItem.id+'-'+rollingStock.rollingStockId" aria-expanded="false" :aria-controls="'collapseOperation-'+operationItem.id+'-'+rollingStock.rollingStockId"
                                        style="border-bottom-left-radius: 0;"
                                        @click="openOperation(operationItem.id+'-'+rollingStock.rollingStockId)"
                                        :class="{
                                'btn-outline-success text-black': operationItem.result && operationItem.result.status == 'clpOk',
                                'btn-outline-primary': operationItem.result && operationItem.result.status == 'clpNone',
                                'btn-outline-warning text-black': operationItem.result && operationItem.result.status == 'clpWarning',
                                'btn-outline-danger text-black': operationItem.result && operationItem.result.status == 'clpError',
                                'btn-outline-secondary': operationItem.result == undefined,
                                }"
                                >{{ operationItem.id }}</button>
                                <button class="btn btn-block text-left shadow-sm d-flex justify-content-between border-left-0"
                                        @click="openOperation(operationItem.id+'-'+rollingStock.rollingStockId)"
                                        :class="{
                                'btn-outline-success text-black': operationItem.result && operationItem.result.status == 'clpOk',
                                'btn-outline-primary': operationItem.result && operationItem.result.status == 'clpNone',
                                'btn-outline-warning text-black': operationItem.result && operationItem.result.status == 'clpWarning',
                                'btn-outline-danger text-black': operationItem.result && operationItem.result.status == 'clpError',
                                'btn-outline-secondary': operationItem.result == undefined,
                                }"
                                        type="button" data-toggle="collapse" :data-target="'#collapseOperation-'+operationItem.id+'-'+rollingStock.rollingStockId" aria-expanded="false" :aria-controls="'collapseOperation-'+operationItem.id+'-'+rollingStock.rollingStockId"
                                        style="border-bottom-right-radius: 0; border-bottom-left-radius: 0;"
                                >
                                    <span v-html="operationItem.caption"></span><i class="fas fa-chevron-up pt-1" :id="'operationChevron'+operationItem.id+'-'+rollingStock.rollingStockId"></i>
                                </button>
                            </div>
                            <div class="collapse" :id="'collapseOperation-'+operationItem.id+'-'+rollingStock.rollingStockId">
                                <div class="card card-body shadow-sm" style="border-radius: 0;">
                                    <OperationAccountDoc
                                            v-if="operationItem.code == 'AccountDoc' && openOperations.includes(''+(operationItem.id+'-'+rollingStock.rollingStockId)+'')"
                                            :rollingStockId="rollingStock.rollingStockId"
                                            :operationItem="operationItem"
                                            :Agent="Agent"
                                            :getCheckListProcess="getCheckListProcess"
                                    />
                                    <OperationGeoPoint
                                            v-if="operationItem.code == 'GeoPoint' && openOperations.includes(''+(operationItem.id+'-'+rollingStock.rollingStockId)+'')"
                                            :rollingStockId="rollingStock.rollingStockId"
                                            :operationItem="operationItem"
                                            :rollingStock="rollingStock"
                                            :Agent="Agent"
                                            :getCheckListProcess="getCheckListProcess"
                                    />
                                    <OperationCloseDocs
                                            v-if="operationItem.code == 'CloseDocs' && openOperations.includes(''+(operationItem.id+'-'+rollingStock.rollingStockId)+'')"
                                            :rollingStockId="rollingStock.rollingStockId"
                                            :operationItem="operationItem"
                                            :rollingStock="rollingStock"
                                            :Agent="Agent"
                                            :getCheckListProcess="getCheckListProcess"
                                    />
                                    <OperationAppCarrier
                                            v-if="operationItem.code == 'AppCarrier' && openOperations.includes(''+(operationItem.id+'-'+rollingStock.rollingStockId)+'')"
                                            :rollingStockId="rollingStock.rollingStockId"
                                            :operationItem="operationItem"
                                            :Agent="Agent"
                                            :getRollingStock="getRollingStock"
                                            :getRollingStocks="getRollingStocks"
                                            :getCheckListProcess="getCheckListProcess"
                                            :rollingStock="rollingStock"
                                    />
                                    <OperationLoading
                                            v-if="operationItem.code == 'Loading' && openOperations.includes(''+(operationItem.id+'-'+rollingStock.rollingStockId)+'')"
                                            :rollingStockId="rollingStock.rollingStockId"
                                            :rollingStock="rollingStock"
                                            :operationItem="operationItem"
                                            :Agent="Agent"
                                            :getCheckListProcess="getCheckListProcess"
                                    />
                                    <OperationUnloading
                                            v-if="operationItem.code == 'Unloading' && openOperations.includes(''+(operationItem.id+'-'+rollingStock.rollingStockId)+'')"
                                            :rollingStockId="rollingStock.rollingStockId"
                                            :rollingStock="rollingStock"
                                            :operationItem="operationItem"
                                            :Agent="Agent"
                                            :getCheckListProcess="getCheckListProcess"
                                    />
                                </div>
                            </div>
                            <div v-if="operationItem.result" class="card card-body bg-light shadow-sm px-2 py-2" style="border-top-right-radius: 0; border-top-left-radius: 0;">
                                <p class="mb-0">
                                    <span v-html="getOperationStatusIcon(operationItem.result ? operationItem.result.status : '')"></span><i>{{ operationItem.result ? operationItem.result.message ? operationItem.result.message : getOperationStatusName(operationItem.result.status) : '' }}</i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import jQuery from 'jquery';
    let $ = jQuery;
    import localStore from '../store';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';

    import OperationAccountDoc from '../rolling-stock/check-list-operations/AccountDoc'
    import OperationGeoPoint from '../rolling-stock/check-list-operations/GeoPoint'
    import OperationCloseDocs from '../rolling-stock/check-list-operations/CloseDocs'
    import OperationAppCarrier from '../rolling-stock/check-list-operations/AppCarrier'
    import OperationLoading from '../rolling-stock/check-list-operations/Loading'
    import OperationUnloading from '../rolling-stock/check-list-operations/Unloading'

    export default {
        name: "DetailRollingStock",
        props: ['rollingStockId', 'Agent', 'getRollingStocks', 'getFavorites'],
        components: {
            DxLoadIndicator,
            OperationAccountDoc,
            OperationGeoPoint,
            OperationCloseDocs,
            OperationAppCarrier,
            OperationLoading,
            OperationUnloading
        },
        data() {
            return {
                rollingStock: null,
                rsCheckListProcess: null,
                rsCheckListProcessOperations: null,
                operationsLoad: true,
                openOperations: [],
                openOperationId: "",
                lastOpenOperationId: ""
            }
        },
        mounted() {
            this.getRollingStock();
        },
        methods: {
            getRollingStock() {
                localStore.dispatch('getRollingStock', {tokenId: this.Agent.tokenId, rollingStockId: this.rollingStockId}).then(() => {
                    this.rollingStock = localStore.state.rollingStock;

                    this.getCheckListProcess();
                });
            },
            getCheckListProcess() {
                this.rsCheckListProcess = null;

                localStore.dispatch('getCheckListProcess', {tokenId: this.Agent.tokenId, rollingStockId: this.rollingStockId}).then(() => {
                    this.rsCheckListProcess = localStore.state.rsCheckListProcess;
                    this.rsCheckListProcessOperations = this.rsCheckListProcess.operations;
                    this.operationsLoad = false;
                })
            },
            openCargo(id) {
                if($('#cargoChevron'+id).hasClass('fa-chevron-right')) {
                    $('#cargoChevron'+id).removeClass('fa-chevron-right');
                    $('#cargoChevron'+id).addClass('fa-chevron-down');
                } else {
                    $('#cargoChevron'+id).removeClass('fa-chevron-down');
                    $('#cargoChevron'+id).addClass('fa-chevron-right');
                }
            },
            arrayRemove(arr, value) {
                return arr.filter(function(ele){
                    return ele != value;
                });
            },
            openOperation(id) {
                if(this.lastOpenOperationId != "" && this.lastOpenOperationId != id) {
                    $('#collapseOperation-'+this.openOperationId).collapse('hide');
                }
                this.changeOperationChevron(id);
                this.openOperationId = id;

                if(localStore.state.operationSelect != this.openOperationId) {
                    localStore.commit('setOperationSelect', this.openOperationId);
                } else {
                    localStore.commit('setOperationSelect', null);
                }

                this.openOperations = this.arrayRemove(this.openOperations, ''+this.openOperationId+'');
                this.openOperations.push(''+this.openOperationId+'');
            },
            changeOperationChevron(id) {

                if($('#operationChevron'+id).hasClass('fa-chevron-up')) {
                    $('#operationChevron'+id).removeClass('fa-chevron-up');
                    $('#operationChevron'+id).addClass('fa-chevron-down');
                } else {
                    $('#operationChevron'+id).removeClass('fa-chevron-down');
                    $('#operationChevron'+id).addClass('fa-chevron-up');
                }

                if(this.lastOpenOperationId != "" && this.lastOpenOperationId != id) {

                    if($('#operationChevron'+this.lastOpenOperationId).hasClass('fa-chevron-down')) {
                        $('#operationChevron'+this.lastOpenOperationId).removeClass('fa-chevron-down');
                        $('#operationChevron'+this.lastOpenOperationId).addClass('fa-chevron-up');

                    }
                }

                this.lastOpenOperationId = id;
            },
            getOperationStatusIcon(status) {
                switch (status) {
                    case 'clpOk':
                        return '<i class="fas fa-check-circle text-success font-18 mr-2"></i>';
                    case 'clpWarning':
                        return '<i class="fas fa-exclamation-triangle text-warning font-18 mr-2" style="color: #cc9a29"></i>';
                    case 'clpError':
                        return '<i class="fas fa-times-circle text-danger font-18 mr-2"></i>';
                }

                return '<i class="fas fa-circle text-black-50 font-18 mr-2"></i>';
            },
            getOperationStatusName(status) {
                switch (status) {
                    case 'clpOk':
                        return 'Ок';
                    case 'clpWarning':
                        return 'Предупреждение';
                    case 'clpError':
                        return 'Ошибка';
                }

                return '-';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .select-rolling-stock {
        -ms-content-zooming: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;
        padding: 0;
        outline: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #212529;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.35715;

        p {
            margin-bottom: 0.5rem;
        }

        .item-text {
            border-style: none none dashed none;
            background: 0 0;
            border-bottom: 1px dashed #ced4da;
            border-radius: 0;
        }
    }

    #accordionCheckListProcess .card-header {
        background-color: #ffffff;
    }
</style>
