<template>
    <div>
        <div v-if="operationItem.result">
            <div v-if="rollingStock.closedDate">
                <DxDataGrid
                        id="closeDocsFiles"
                        key-expr="fileId"
                        :data-source="operationItem.result.closedFiles"
                        :columns="columnsFiles"
                        :show-borders="true"
                        :row-alternation-enabled="true"
                        :show-row-lines="true"
                        :customize-columns="customizeColumnsFiles"
                >
                    <template #is-verified-template="{ data }">
                        <i v-if="data.data.isVerified" class="fas fa-award font-22" style="color: #00afef"></i>
                    </template>
                    <template #file-name-template="{ data }">
                        <button :id="'btn-download-file-'+data.data.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(data.data.fileId, data.data.fileName)">{{ data.data.fileName }}</button>
                    </template>
                </DxDataGrid>
            </div>
            <div v-if="!rollingStock.closedDate">
                <div v-if="okMessage" class="alert alert-success mb-3" role="alert">
                    {{ okMessage }}
                </div>
                <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                    <strong>Внимание!</strong> {{ errorMessage }}
                </div>
                <DxDataGrid
                        id="closeDocsFilesEdit"
                        key-expr="fileId"
                        :data-source="operationItem.result.closedFiles"
                        :columns="columnsFiles"
                        :show-borders="true"
                        :row-alternation-enabled="true"
                        :show-row-lines="true"
                        :customize-columns="customizeColumnsFilesEdit"
                        :editor-prepared="onEditorPrepared"
                        @toolbar-preparing="onToolbarPreparing"
                        @editing-start="onEditingStart"
                        @init-new-row="onInitNewRow"
                        @saving="onSaving"
                >
                    <DxEditing
                            :allow-updating="true"
                            :allow-deleting="true"
                            :allow-adding="true"
                            mode="form"
                    >
                        <DxForm
                                :col-count="7"
                                :show-colon-after-label="true"
                                :show-validation-summary="true"
                        >
                            <DxColCountByScreen :xs="1" :sm="1"/>
                        </DxForm>
                    </DxEditing>
                    <DxColumn
                            :width="40"
                            :allow-sorting="false"
                            data-field="isVerified"
                            caption=""
                            :allow-editing="false"
                            cell-template="is-verified-template"
                    >
                        <DxFormItem :visible="false" />
                    </DxColumn>
                    <DxColumn
                            :width="100"
                            :allow-sorting="false"
                            data-field="closedDate"
                            caption="Дата ЗП"
                            data-type="date"
                            format="dd.MM.yyyy"
                    >
                        <DxFormItem :col-span="1">
                            <DxLabel text="Дата ЗП" location="top" />
                        </DxFormItem>
                        <DxRequiredRule message="Укажите Дату ЗП"/>
                    </DxColumn>
                    <DxColumn
                            :width="120"
                            :allow-sorting="false"
                            data-field="fileKind"
                            caption="Вид документа"
                    >
                        <DxFormItem :col-span="2"
                                    :editor-options="{
                                        dataSource: fileKinds,
                                        acceptCustomValue: true,
                                        onCustomItemCreating: onCustomItemCreating
                                    }"
                                    editor-type="dxSelectBox"
                        >
                            <DxLabel text="Вид документа" location="top" />
                        </DxFormItem>
                        <DxRequiredRule message="Укажите Вид документа"/>
                    </DxColumn>
                    <DxColumn
                            :allow-sorting="false"
                            data-field="fileDoc"
                            caption="Файл"
                            :visible="false"
                            edit-cell-template="file-doc-editor"
                    >
                        <DxFormItem :col-span="4" :editor-options="{value: fileDocLabel}">
                            <DxLabel text="Файл" location="top" />
                            <DxRequiredRule message="Выберите файл"/>
                        </DxFormItem>
                    </DxColumn>
                    <DxColumn
                            :allow-sorting="false"
                            data-field="fileName"
                            caption="Имя файла"
                            :allow-editing="false"
                            cell-template="file-name-template"
                    >
                        <DxFormItem :visible="false" />
                    </DxColumn>
                    <DxColumn
                            :allow-sorting="false"
                            data-field="note"
                            caption="Примечание"
                    >
                        <DxFormItem :col-span="7">
                            <DxLabel text="Примечание" location="top" />
                        </DxFormItem>
                    </DxColumn>
                    <DxColumn
                            type="buttons"
                            caption="Действие"
                    >
                        <DxButton
                                name="edit"
                                text="Изменить"
                                css-class="btn btn-light btn-sm text-primary d-block d-md-inline-block"
                        />
                        <DxButton
                                name="delete"
                                css-class="btn btn-light btn-sm text-danger d-block d-md-inline-block"
                                text="Удалить"
                                :disabled="fileDocImageSource == ''"
                        />
                    </DxColumn>
                    <template #file-doc-editor>
                        <div>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <span v-if="fileDocLabel">{{ fileDocLabel }}</span>
                                    <span v-if="!fileDocLabel" class="text-danger">Файл не выбран</span>
                                </div>
                                <div class="col-12 col-md-6 text-right">
                                    <DxButtonST
                                            id="fileDoc-add-external"
                                            text="Выбрать файл"
                                            type="default"
                                            styling-mode="contained"
                                    />
                                </div>
                            </div>
                            <DxFileUploader
                                    id="fileDoc"
                                    dialog-trigger="#fileDoc-add-external"
                                    :multiple="false"
                                    upload-mode="useForm"
                                    :visible="false"
                                    accept="image/jpeg,image/jpg,image/png,application/pdf"
                                    @value-changed="fileDocValueChanged"
                            />
                        </div>
                    </template>
                    <template #is-verified-template="{ data }">
                        <i v-if="data.data.isVerified" class="fas fa-award font-22" style="color: #00afef"></i>
                    </template>
                    <template #file-name-template="{ data }">
                        <button :id="'btn-download-file-'+data.data.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(data.data.fileId, data.data.fileName)">{{ data.data.fileName }}</button>
                    </template>
                </DxDataGrid>
            </div>
        </div>
    </div>
</template>

<script>
    import jQuery from 'jquery';
    let $ = jQuery;
    import {httpClient} from '../../../../shared/services';
    import {
        DxDataGrid,
        DxEditing,
        DxForm,
        DxFormItem,
        DxColumn,
        DxRequiredRule,
        DxButton
    } from 'devextreme-vue/data-grid';
    import {
        DxLabel,
        DxColCountByScreen
    } from 'devextreme-vue/form';
    import { DxFileUploader } from 'devextreme-vue/file-uploader';
    import { DxButton as DxButtonST } from 'devextreme-vue/button';
    import moment from 'moment';

    async function insertDoc(data, rollingStockId, routePointId, fileDocLabel, fileDocImageSource, tokenId, getCheckListProcess, encodeURL) {
        try {
            let closedDate = moment(data.closedDate).format('YYYY-MM-DDTHH:mm:ss');
            let file = fileDocImageSource.replace("data:", "").replace(/^.+,/, "");
            let note = data.note != undefined ? data.note : "";
            fileDocLabel = encodeURL(fileDocLabel);

            await httpClient.post(`v2/Carrier/RollingStocks/${rollingStockId}/UnloadingDocs?routePointId=${routePointId}&fileKind=${data.fileKind}&fileName=${fileDocLabel}&closedDate=${closedDate}&note=${note}&tokenId=${tokenId}`,
                `"${file}"`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            //console.log(resp.data);
            getCheckListProcess();
        } catch (error) {
            // Handle Error Here
            console.error(error);
            if(error.response.data) {
                let errorMessage = error.response.data;

                throw errorMessage;
            }
        }
    }

    async function updateDoc(data, rollingStockId, routePointId, fileDocLabel, fileDocImageSource, tokenId, getCheckListProcess, fileId, encodeURL) {
        try {
            let closedDate = moment(data.closedDate).format('YYYY-MM-DDTHH:mm:ss');
            let note = data.note != undefined ? data.note : "";

            let file = fileDocImageSource != "" ? fileDocImageSource.replace("data:", "").replace(/^.+,/, "") : "";
            fileDocLabel = encodeURL(fileDocLabel);

            file != "" ? await httpClient.post(`v2/Carrier/RollingStocks/${rollingStockId}/UnloadingDocs?fileId=${fileId}&routePointId=${routePointId}&fileKind=${data.fileKind}&fileName=${fileDocLabel}&closedDate=${closedDate}&note=${note}&tokenId=${tokenId}`,
                `"${file}"`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }) : await httpClient.put(`v2/Carrier/RollingStocks/${rollingStockId}/UnloadingDocs?fileId=${fileId}&routePointId=${routePointId}&fileKind=${data.fileKind}&closedDate=${closedDate}&note=${note}&tokenId=${tokenId}`);
            //console.log(resp.data);
            getCheckListProcess();
        } catch (error) {
            // Handle Error Here
            console.error(error);
            if(error.response.data) {
                let errorMessage = error.response.data;

                throw errorMessage;
            }
        }
    }

    async function removeDoc(fileId, rollingStockId, tokenId, getCheckListProcess) {
        try {
            await httpClient.delete(`v2/Carrier/RollingStocks/${rollingStockId}/UnloadingDocs/${fileId}?tokenId=${tokenId}`);
            //console.log(resp.data);
            getCheckListProcess();
        } catch (error) {
            // Handle Error Here
            console.error(error);
            if(error.response.data) {
                let errorMessage = error.response.data;

                throw errorMessage;
            }
        }
    }

    export default {
        name: "OperationCloseDocs",
        props: ['rollingStockId', 'operationItem', 'Agent', 'getCheckListProcess', 'rollingStock'],
        components: {
            DxDataGrid,
            DxEditing,
            DxForm,
            DxColCountByScreen,
            DxLabel,
            DxFormItem,
            DxRequiredRule,
            DxColumn,
            DxButton,
            DxFileUploader,
            DxButtonST
        },
        data() {
            return {
                okMessage: "",
                errorMessage: "",
                columnsFiles: [
                    {
                        caption: '',
                        dataField: 'isVerified',
                        allowSorting: false,
                        cellTemplate: "is-verified-template",
                        width: 40
                    },
                    {
                        caption: 'Дата ЗП',
                        dataField: 'closedDate',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: false,
                        width: 100,
                    },
                    {
                        caption: 'Вид документа',
                        dataField: 'fileKind',
                        allowSorting: false,
                        width: 120
                    },
                    {
                        caption: 'Имя файла',
                        dataField: 'fileName',
                        allowSorting: false,
                        encodeHtml: false,
                        cellTemplate: "file-name-template",
                        allowHiding: false,
                    },
                    {
                        caption: 'Примечание',
                        dataField: 'note',
                        allowSorting: false,
                        width: 500
                    }
                ],
                fileDocImageSource: "",
                fileDoc: null,
                fileDocLabel: "",
                fileDocId: "",
                data: null,
                fileKinds: ["ТТН", "CMR", "ТПГ"]
            }
        },
        methods: {
            downloadFile(fileId, fileName) {
                $('#btn-download-file-'+fileId).html('Загрузка...');
                $('#btn-download-file-'+fileId).prop('disabled', true);

                httpClient.get(`v2/FilesStarage/${fileId}/Download?tokenId=${this.Agent.tokenId}`)
                    .then((file) => {
                        let fileExt = fileName.split('.').pop();

                        const linkSource = `data:application/${fileExt};base64,${file.data}`;
                        const downloadLink = document.createElement('a');
                        document.body.appendChild(downloadLink);

                        downloadLink.href = linkSource;
                        downloadLink.target = '_self';
                        downloadLink.download = fileName;
                        downloadLink.click();

                        downloadLink.remove();

                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    })
                    .catch(() => {
                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    });
            },
            customizeColumnsFiles(columns) {
                if(columns.length > 0) {
                    if(this.$mq == 'sm' || this.$mq == 'md' || this.$mq == 'lg') {
                        columns[4].hidingPriority = 1;
                        columns[3].hidingPriority = 2;
                        columns[2].hidingPriority = 3;
                        columns[1].hidingPriority = 4;
                        columns[0].hidingPriority = 5;
                        columns[0].width = '40px';
                        columns[1].width = '90px';
                        // columns[3].width = '50px';
                    } else {
                        columns[4].hidingPriority = null;
                        columns[3].hidingPriority = null;
                        columns[2].hidingPriority = null;
                        columns[1].hidingPriority = null;
                        columns[0].hidingPriority = null;
                    }
                }
            },
            changeColumns(columns) {
                if(columns.length > 0) {
                    columns[0].width = '40px';
                    columns[1].visible = true;
                    columns[2].visible = true;
                    columns[3].visible = false;
                    columns[4].visible = true;
                    columns[5].visible = true;
                    columns[6].visible = true;

                    if(this.$mq == 'sm') {
                        columns[2].hidingPriority = 2;
                        columns[3].hidingPriority = 3;
                        columns[4].hidingPriority = 4;
                        columns[5].hidingPriority = 5;
                        columns[0].width = '30px';
                        columns[1].width = '90px';
                        columns[6].width = '100px';
                    } else if(this.$mq == 'md' || this.$mq == 'lg') {
                        columns[3].hidingPriority = 3;
                        columns[4].hidingPriority = 4;
                        columns[5].hidingPriority = 5;
                        columns[0].width = '40px';
                        columns[1].width = '90px';
                    } else {
                        columns[6].hidingPriority = null;
                        columns[5].hidingPriority = null;
                        columns[4].hidingPriority = null;
                        columns[3].hidingPriority = null;
                        columns[2].hidingPriority = null;
                        columns[1].hidingPriority = null;
                        columns[0].hidingPriority = null;
                    }
                }
            },
            customizeColumnsFilesEdit(columns) {
                this.changeColumns(columns);
            },
            onEditorPrepared(e) {
                console.log(e);
            },
            onToolbarPreparing(e) {
                e.toolbarOptions.items[0].showText = 'always';
                e.toolbarOptions.items[0].options.text = 'Добавить файл'
            },
            fileDocValueChanged(args) {
                if(args.value[0]) {
                    this.fileDoc = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.fileDocImageSource = fileReader.result;
                        this.fileDocLabel = file.name;

                        this.okMessage = "";
                        this.errorMessage = "";
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            onInitNewRow() {
                this.fileDoc = null;
                this.fileDocImageSource = "";
                this.fileDocLabel = "";
                this.fileDocId = "";
                this.fileKinds = ["ТТН", "CMR", "ТПГ"];
            },
            onEditingStart(e) {
                this.data = e.data;
                this.fileDocLabel = e.data.fileName;
                this.fileDocId = e.data.fileId;
                this.fileKinds = ["ТТН", "CMR", "ТПГ"];
                this.addInFileKinds(e.data.fileKind);
            },
            onSaving(e) {
                if(e.changes && e.changes.length == 1 && e.changes[0]) {
                    switch (e.changes[0].type) {
                        case 'insert':
                            e.cancel = this.fileDoc == null;

                            if(!e.cancel) {
                                e.promise = insertDoc(e.changes[0].data, this.rollingStockId, this.operationItem.result.routePointId, this.fileDocLabel, this.fileDocImageSource, this.Agent.tokenId, this.getCheckListProcess, this.$options.filters.encodeURL);
                            } else {
                                if(this.fileDoc == null) {
                                    this.errorMessage = "Вы не выбрали файл.";
                                }
                            }
                            break;
                        case 'update':
                            e.cancel = this.fileDocLabel == "";

                            if(!e.cancel) {
                                if(e.changes[0].data) {
                                    if(e.changes[0].data.closedDate != undefined) {
                                        this.data.closedDate = e.changes[0].data.closedDate;
                                    }

                                    if(e.changes[0].data.fileKind != undefined) {
                                        this.data.fileKind = e.changes[0].data.fileKind;
                                    }

                                    if(e.changes[0].data.note != undefined) {
                                        this.data.note = e.changes[0].data.note;
                                    }
                                }

                                e.promise = updateDoc(this.data, this.rollingStockId, this.operationItem.result.routePointId, this.fileDocLabel, this.fileDocImageSource, this.Agent.tokenId, this.getCheckListProcess, e.changes[0].key, this.$options.filters.encodeURL);
                            } else {
                                if(this.fileDoc == null) {
                                    this.errorMessage = "Вы не выбрали файл.";
                                }
                            }
                            break;
                        case 'remove':
                            if(!e.cancel) {
                                e.promise = removeDoc(e.changes[0].key, this.rollingStockId, this.Agent.tokenId, this.getCheckListProcess);
                            }
                            break;
                    }
                }
            },
            addInFileKinds(obj) {
                if(!this.fileKinds.includes(obj)) {
                    this.fileKinds.push(obj);
                }
            },
            onCustomItemCreating(e) {
                this.addInFileKinds(e.text);

                return e.text;
            }
        }
    }
</script>

<style scoped>

</style>
