<template>
    <div>
        <div v-if="operationItem.result">
            <div v-if="rollingStock.closedDate">
                <DxDataGrid
                        id="gridGeoPoint"
                        :data-source="operationItem.result.locations"
                        :columns="columnsGeoPoint"
                        :show-borders="true"
                        :row-alternation-enabled="true"
                        :show-row-lines="true"
                        :customize-columns="customizeColumnsGeoPoint"
                ></DxDataGrid>
            </div>
            <div v-if="!rollingStock.closedDate">
                <DxDataGrid
                        id="gridGeoPointEdit"
                        key-expr="geoPointId"
                        :data-source="operationItem.result.locations"
                        :show-borders="true"
                        :row-alternation-enabled="true"
                        :show-row-lines="true"
                        :customize-columns="customizeColumnsGeoPointEdit"
                        @editing-start="onEditingStart"
                        @saving="onSaving"
                        @toolbar-preparing="onToolbarPreparing"
                >
                    <DxEditing
                            :allow-updating="true"
                            :allow-deleting="true"
                            :allow-adding="true"
                            mode="form"
                    >
                        <DxForm
                                :col-count="4"
                                :show-colon-after-label="true"
                                :show-validation-summary="true"
                        />
                    </DxEditing>
                    <DxColCountByScreen :xs="1" :sm="1"/>
                    <DxColumn
                            :width="140"
                            :allow-sorting="false"
                            data-field="locationTime"
                            caption="Дата отметки"
                            data-type="datetime"
                            format="dd.MM.yyyy HH:mm"
                    >
                        <DxFormItem :col-span="1">
                            <DxLabel text="Дата отметки" location="top" />
                        </DxFormItem>
                        <DxRequiredRule message="Укажите дату отметки"/>
                        <DxCustomRule :message="validateLocationTimeText" type="custom" :reevaluate="true" :validation-callback="validateLocationTime" />
                    </DxColumn>
                    <DxColumn
                            :allow-sorting="false"
                            data-field="note"
                            caption="Описание"
                    >
                        <DxFormItem :col-span="3">
                            <DxLabel text="Описание" location="top" />
                        </DxFormItem>
                        <DxRequiredRule message="Укажите описание отметки"/>
                    </DxColumn>
                    <DxColumn
                            type="buttons"
                            :width="210"
                            caption="Действие"
                    >
                        <DxButton
                                name="edit"
                                text="Изменить"
                                css-class="btn btn-light btn-sm text-primary d-block d-md-inline-block"
                        />
                        <DxButton
                                name="delete"
                                css-class="btn btn-light btn-sm text-danger d-block d-md-inline-block"
                                text="Удалить"
                        />
                    </DxColumn>
                </DxDataGrid>
            </div>
        </div>
    </div>
</template>

<script>
    import {httpClient} from '../../../../shared/services';
    import {
        DxDataGrid,
        DxEditing,
        DxColumn,
        DxFormItem,
        DxForm,
        DxButton
    } from 'devextreme-vue/data-grid';
    import {
        DxRequiredRule,
        DxLabel,
        DxCustomRule,
        DxColCountByScreen
    } from 'devextreme-vue/form';
    import moment from 'moment';

    async function insertGP(data, rollingStockId, tokenId, getCheckListProcess) {
        try {
            let locationTime = moment(data.locationTime).format('YYYY-MM-DDTHH:mm:ss');

            await httpClient.post(`v2/Carrier/RollingStocks/${rollingStockId}/GeoPoints?tokenId=${tokenId}`, {
                locationTime: locationTime,
                note: data.note
            });
            getCheckListProcess();
        } catch (error) {
            // Handle Error Here
            console.error(error);
            let errorMessage = "Ошибка при сохранении данных. Проверьте указанные вами данные.";

            if (error.response.status === 400) {
                if(error.response.data) {
                    errorMessage = error.response.data;

                    errorMessage = errorMessage.replace ? errorMessage.replace('Параметр "locationTime", задан неверно! ', '') : "Ошибка при сохранении данных. Проверьте указанные вами данные.";
                }
            }

            throw errorMessage;
        }
    }

    async function updateGP(data, rollingStockId, tokenId, getCheckListProcess) {
        try {
            let locationTime = moment(data.locationTime).format('YYYY-MM-DDTHH:mm:ss');

            await httpClient.post(`v2/Carrier/RollingStocks/${rollingStockId}/GeoPoints?tokenId=${tokenId}`, {
                geoPointId: data.geoPointId,
                locationTime: locationTime,
                note: data.note
            });
            getCheckListProcess();
        } catch (error) {
            // Handle Error Here
            console.error(error);
            let errorMessage = "Ошибка при обновлении данных. Проверьте указанные вами данные.";

            if (error.response.status === 400) {
                if(error.response.data) {
                    errorMessage = error.response.data;

                    errorMessage = errorMessage.replace ? errorMessage.replace('Параметр "locationTime", задан неверно! ') : "Ошибка при обновлении данных. Проверьте указанные вами данные.";
                }
            }

            throw errorMessage;
        }
    }

    async function removeGP(geoPointId, rollingStockId, tokenId, getCheckListProcess) {
        try {
            await httpClient.delete(`v2/Carrier/RollingStocks/${rollingStockId}/GeoPoints/${geoPointId}?tokenId=${tokenId}`);
            getCheckListProcess();
        } catch (error) {
            // Handle Error Here
            console.error(error);
            let errorMessage = "Ошибка при удалении данных. Попробуйте еще раз.";

            throw errorMessage;
        }
    }

    export default {
        name: "OperationGeoPoint",
        props: ['rollingStockId', 'operationItem', 'Agent', 'getCheckListProcess', 'rollingStock'],
        components: {
            DxDataGrid,
            DxEditing,
            DxRequiredRule,
            DxColumn,
            DxFormItem,
            DxLabel,
            DxCustomRule,
            DxColCountByScreen,
            DxForm,
            DxButton
        },
        data() {
            return {
                columnsGeoPoint: [
                    {
                        caption: 'Дата отметки',
                        dataField: 'locationTime',
                        dataType: 'datetime',
                        format: 'dd.MM.yyyy HH:mm',
                        allowSorting: false,
                        width: 140,
                    },
                    {
                        caption: 'Описание',
                        dataField: 'note',
                        allowSorting: false
                    }
                ],
                data: null,
                validateLocationTimeText: 'Указанное вами время должно быть в диапазоне от ' + moment(this.operationItem.result.minDate).format('DD.MM.YYYY HH:mm:ss') + ' до ' + moment(this.operationItem.result.maxDate).format('DD.MM.YYYY HH:mm:ss')
            }
        },
        methods: {
            customizeColumnsGeoPoint(columns) {
                if(columns.length > 0) {
                    columns[0].width = '140px';

                    if (this.$mq == 'sm' || this.$mq == 'md') {
                        columns[1].hidingPriority = 1;
                        columns[0].width = '110px';
                    } else {
                        columns[1].hidingPriority = null;
                    }
                }
            },
            customizeColumnsGeoPointEdit(columns) {
                if(columns.length > 0) {
                    columns[0].width = '140px';
                    columns[2].width = '210px';

                    if (this.$mq == 'sm' || this.$mq == 'md') {
                        columns[2].hidingPriority = 1;
                        columns[1].hidingPriority = 0;
                        columns[0].width = '110px';
                        columns[2].width = '100%';
                    } else {
                        columns[1].hidingPriority = null;
                        columns[2].hidingPriority = null;
                    }
                }
            },
            onEditingStart(e) {
                this.data = e.data;
            },
            onSaving(e) {
                if(e.changes && e.changes.length == 1 && e.changes[0]) {
                    switch (e.changes[0].type) {
                        case 'insert':
                            if(!e.cancel) {
                                e.promise = insertGP(e.changes[0].data, this.rollingStockId, this.Agent.tokenId, this.getCheckListProcess);
                            }
                            break;
                        case 'update':
                            e.cancel = this.fileDocLabel == "";

                            if(!e.cancel) {
                                if(e.changes[0].data) {
                                    if(e.changes[0].data.locationTime != undefined) {
                                        this.data.locationTime = e.changes[0].data.locationTime;
                                    }

                                    if(e.changes[0].data.note != undefined) {
                                        this.data.note = e.changes[0].data.note;
                                    }
                                }

                                e.promise = updateGP(this.data, this.rollingStockId, this.getCheckListProcess, e.changes[0].key);
                            }
                            break;
                        case 'remove':
                            if(!e.cancel) {
                                e.promise = removeGP(e.changes[0].key, this.rollingStockId, this.Agent.tokenId, this.getCheckListProcess);
                            }
                            break;
                    }
                }
            },
            validateLocationTime(e) {
                if(e.value != null) {
                    var d1 = new Date(this.operationItem.result.minDate);
                    var d2 = new Date(this.operationItem.result.maxDate);
                    var d3 = new Date(e.value);

                    return d1 <= d3 && d2 >= d3;
                }

                return true;
            },
            onToolbarPreparing(e) {
                e.toolbarOptions.items[0].showText = 'always';
                e.toolbarOptions.items[0].options.text = 'Добавить отметку'
            }
        }
    }
</script>

<style scoped>

</style>
