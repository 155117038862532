export default {
    defaultsoutes: [
        {
            id: 1,
            name: 'CarrierOffers',
            title: 'Ценовые предложения',
            hash: ''
        },
        {
            id: 2,
            name: 'CarrierRollingStocks',
            title: 'Перевозки',
            hash: ''
        }
    ]
}


