import Vue from 'vue'
import Vuex from 'vuex'
import {httpClient} from '../../shared/services';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        car: null,
        carFiles: null,
        driver: null,
        driverFiles: null,
        offer: null,
        currencys: null,
        cargoLot: null,
        rollingStocksFavorites: null,
        rollingStock: null,
        rsCheckListProcess: null,
        operationSelect: null,
        route: null,
        offers: null
    },
    mutations: {
        setCar(state, data) {
            state.car = data;
        },
        setCarFiles(state, data) {
            state.carFiles = data;
        },
        setDriver(state, data) {
            state.driver = data;
        },
        setDriverFiles(state, data) {
            state.driverFiles = data;
        },
        setOffer(state, data) {
            state.offer = data;
        },
        setCurrencys(state, data) {
            state.currencys = data;
        },
        setCargoLot(state, data) {
            state.cargoLot = data;
        },
        setRollingStocksFavorites(state, data) {
            state.rollingStocksFavorites = data;
        },
        setRollingStock(state, data) {
            state.rollingStock = data;
        },
        setCheckListProcess(state, data) {
            state.rsCheckListProcess = data;
        },
        setOperationSelect(state, data) {
            state.operationSelect = data;
        },
        setRoute(state, data) {
            state.route = data;
        },
        setOffers(state, data) {
            state.offers = data;
        }
    },
    actions: {
        getCar({commit}, {tokenId, carId}) {
            commit('setCar', null);

            return httpClient
                .get(`v2/Carrier/Cars/${carId}?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setCar', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getCarFiles({commit}, {tokenId, carId}) {
            commit('setCarFiles', null);
            return httpClient
                .get(`v2/Carrier/Cars/${carId}/Files?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setCarFiles', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getDriverFiles({commit}, {tokenId, driverId}) {
            commit('setDriverFiles', null);
            return httpClient
                .get(`v2/Carrier/Drivers/${driverId}/Files?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setDriverFiles', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getDriver({commit}, {tokenId, driverId}) {
            commit('setDriver', null);

            return httpClient
                .get(`v2/Carrier/Drivers/${driverId}?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setDriver', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getOffer({commit}, {tokenId, offerId}) {
            commit('setOffer', null);

            return httpClient
                .get(`v2/Carrier/Offers/${offerId}?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setOffer', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getCurrencys({commit}) {
            commit('setCurrencys', null);
            return httpClient
                .get('v2/Catalog/Currencys')
                .then((response) => {
                    commit('setCurrencys', response.data.items);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getCargoLot({commit}, {tokenId, cargoLotId}) {
            commit('setCargoLot', null);

            return httpClient
                .get(`v2/Marketplace/Cargos/${cargoLotId}?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setCargoLot', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getRollingStocksFavorites({commit}, {tokenId, skeep, take}) {
            commit('setRollingStocksFavorites', null);

            return httpClient
                .get(`v2/Carrier/RollingStocks/Favorites?tokenId=${tokenId}&skeep=${skeep}&take=${take}`)
                .then((response) => {
                    commit('setRollingStocksFavorites', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getRollingStock({commit}, {tokenId, rollingStockId}) {
            commit('setRollingStock', null);

            return httpClient
                .get(`v2/Carrier/RollingStocks/${rollingStockId}?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setRollingStock', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getCheckListProcess({commit}, {tokenId, rollingStockId}) {
            commit('setCheckListProcess', null);

            return httpClient
                .get(`v2/Carrier/RollingStocks/${rollingStockId}/CheckListProcess?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setCheckListProcess', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getRoute({commit}, {tokenId, cargoLotId}) {
            commit('setRoute', null);
            let url = `v2/Marketplace/Cargos/${cargoLotId}/Route`;

            if (tokenId) {
                url = url + `?tokenId=${tokenId}`;
            }

            return httpClient
                .get(url)
                .then((response) => {
                    commit('setRoute', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getOffers({commit}, {tokenId, cargoLotId}) {
            commit('setOffers', null);
            let url = `v2/Marketplace/Cargos/${cargoLotId}/Offers`;

            if (tokenId) {
                url = url + `?tokenId=${tokenId}`;
            }

            return httpClient
                .get(url)
                .then((response) => {
                    commit('setOffers', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        }
    },
    getters: {},
    modules: {}
});
